import { addCar, updateCar } from "@/pages/fleet/carInfo/service";
import contextApi from "@/utils/contextApi";

const Model = {
  namespace: "carInfoModel",
  state: {
    editVisible: false,
    record: null,
    // 模板信息
    fileList: [],
    errUrl: null,
  },
  effects: {
    *patch({ payload }, { put }) {
      yield put({
        type: "setState",
        payload,
      });
    },
    /**
     * 新增车辆
     * @param payload
     * @param call
     * @param put
     * @returns {Generator<*, void, *>}
     */
    *submitCarInfo({ payload }, { call, put }) {
      const res = yield call(addCar, payload);

      if (res && res.code === "200") {
        const data = res.data;
        // 如果是批量新增
        if (payload.flag === 2) {
          if (data.failCount === null) {
            contextApi("message").error(data.failFileInfo);
          }
          if (data.failCount === 0&&data.successCount !== 0) {
            contextApi("message").success("批量新增成功");
            yield put({
              type: "setState",
              payload: {
                editVisible: false,
                fileIdList: [],
              },
            });
          } else {
            contextApi("message").warning(
              `成功${data.successCount}条，失败${data.failCount}条`
            );
            yield put({
              type: "setState",
              payload: {
                errUrl: data.failFileInfo,
              },
            });
          }
        } else {
          contextApi("message").success("新增成功");
          yield put({
            type: "setState",
            payload: {
              editVisible: false,
            },
          });
        }
        yield put({
          type: "refresh",
        });
      }
    },
    /**
     * 修改车辆信息
     * @param payload
     * @param call
     * @param put
     * @returns {Generator<*, void, *>}
     */
    *editCarInfo({ payload }, { call, put }) {
      const res = yield call(updateCar, payload);

      if (res && res.code === "200") {
        yield put({
          type: "setState",
          payload: {
            editVisible: false,
          },
        });
        contextApi("message").success("操作成功");
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      console.log({ ...state, ...payload });
      return { ...state, ...payload };
    },
  },
};
export default Model;
