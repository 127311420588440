var api = require("!../node_modules/@umijs/deps/compiled/style-loader/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../node_modules/@umijs/deps/compiled/css-loader/cjs.js??ref--13-oneOf-1-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/@umijs/deps/compiled/less-loader/cjs.js??ref--13-oneOf-1-3!./global.less");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {"base":0};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};