export default {
  layout: {
    account: "賬號",
    notification: "通知",
    logoff: "註銷",
    information: "我的信息",
    changePass: "修改密碼",
    current: "當前車隊",
    add: "添加",
    default: "默認",
    delete: "刪除",
  },
  newLogin: {
    title: "智能充電車隊管理系統",
    footer: "",
    navTips: "The browser you are currently using is not compatible",
    adviceNav:
      "In order to provide you with better use experience, it is recommended to use Google Chrome",
    versionNumber: "版本號",
    passwordTips: "請輸入密碼",
    usernameTips: "請輸入您的賬號",
    VerificationCode: "驗證碼",
    tips: "驗證碼錯誤",
    codeTips: "請輸入驗證碼",
    login: "登錄",
    verificationCode: "驗證碼",
    account: "賬號",

    password: "密碼",
    telTips: "請輸入您的手機號",
    immediateVerification: "立即校驗",
    verificationTips: "請發送驗證碼",
    sendLater: "秒後重新發送",
    GetAuthenticationCode: "獲取驗證碼",
    modifyPassword: "修改密碼",
    resetPasswordTip: "當前密碼過於簡單，為保證您的賬號安全，請立即修改密碼。",
    resetPassword: "重置密碼",
    originalPassword: "當前密碼",
    EnterOriginalPassword: "請輸入原登錄密碼",
    confirmPassword: "確認密碼",
    newPassword: "新密碼",
    forgotPwd: "忘記密碼",
    TypeNewPasswordAgain: "確認密碼",
    EnterNewPassword: "請輸入新密碼",
    clickEnter: "點擊進入",
    second: "Remaining Time:",
    areaSelectTips: "根据合约所在地，选择数据大区",
    PasswordCombination: "至少12位大小寫字母數字加特殊字符組合",
    tips1: "修改密碼成功，請重新登錄",
    tips2: "兩次密碼輸入不一致",
    tips3: "請輸入確認密碼",
  },
  menu: {
    dashboard: "首頁",
    首页: "首頁",
    订单中心: "訂單中心",
    充电订单: "充電訂單",
    占位订单: "佔位訂單",
    车队管理: "車隊管理",
    车辆信息: "車輛信息",
    电卡管理: "電卡管理",
    Vin码鉴权: "Vin碼鑑權",
    充电电站: "充電電站",
    员工充电报表: "員工充電報表",
    车辆充电报表: "車輛充電報表",
    财务管理: "財務管理",
    司机账户: "司機賬戶",
    新增账户: "新增賬戶",
    修改账户: "修改賬戶",
    变动记录: "變動記錄",
    发票申请: "發票申請",
    开票历史: "開票歷史",
    充电账单: "充電賬單",
    账单明细: "賬單明細",
    生成账单: "生成賬單",
    基础信息: "基礎信息",
    我的信息: "我的信息",
    基础设置: "基礎設置",
    管理员: "管理員",
    修改密码: "修改密碼",
  },
  page: {
    filter: {
      beginTime: "開始時間",
      endTime: "結束時間",

      emptyNotice: "請輸入",
      dateAdded: "添加日期",
      administratorAccount: "管理員賬號",
      administratorName: "管理員姓名",
      accountStatus: "賬號狀態",
      selectEmptyNotice: "請選擇",
      select: "選擇",
      reset: "重置",
      inquire: "查詢",
      addManager: "新增管理員",
      enterAdministratorName: "請輸入管理員姓名",
      maxEnter: "最多輸入30個字符",
      enterAdministratorAccount: "請輸入管理員賬號",
      Pleaseenterkeywords: "請輸入關鍵詞",
      chargingEndTime: "日期範圍",
      todayButton: "今",
      yesterdayButton: "昨",
      recent7Days: "近7日",
      recent30Days: "近30日",
      stationName: "電站名稱",
      stationNamePlaceholder: "請選擇電站",
      chargingGunNumber: "充電插頭編號",
      chargingGunNumberPlaceholder: "請精確輸入充電插頭編號",
      userAccount: "用戶賬號",
      userAccountPlaceholder: "請輸入11位手機號",
      PleaseEnterEmailAddress: "請輸入郵箱",
      orderNumber: "訂單號",
      orderNumberPlaceholder: "請精確輸入訂單號",
      numberplate: "車輛信息",
      numberplatePlaceholder: "請精確輸入",
      activationMethod: "啟動方式",
      activationMethodPlaceholder: "請選擇啟動方式",
      chargingStatus: "充電狀態",
      chargingStatusPlaceholder: "請選擇充電狀態",
      exportReport: "導出報表",
      PleaseUploadTheFile: "請上傳文件",
      ModifiedSuccessfully: "修改成功",
      OrdPassword: "原密碼",
      PleaseEnterOriginalPassword: "請輸入原密碼",
      NewPassword: "新密碼",
      PleaseEnterNewPassword: "請輸入新密碼",
      ConfirmPassword: "確認密碼",
      PleaseEnterTheNewPasswordAgain: "請再次輸入新密碼",
      FundData: "資金數據",
      GetSMSode: "獲取驗證碼",
      SentSuccessfully: "發送成功",
      OperationSuccessfully: "操作成功",
      AccountAllocation: "賬戶分配",
      CurrentAccount: "當前賬號",
      DistributionAmount: "分配金額(HK$)",
      AccountRecovery: "賬戶回收",
      EnterAmount: "輸入金額(HK$)",
      FullRecovery: "全部回收",
      CustomAmount: "自定義金額",
      RecoveryAmount: "請輸入回收金額",
      BatchAdditionSucceeded: "批量新增成功",
      tips1: "單次查詢的最大區間是31天",
      tips2: "數據統計時間為截至當前時間。",
      tips3: "禁用後將關閉該賬號充電功能，確認禁用？",
      tips4: "確認啟用？",
      tips5: "當前司機正在充電，是否停止充電？",
      tips6: "訂單號和充電結束時間必填一項",
      Allocatable: "最大可分配",
      PleaseEnterANumberGreater: "請輸入大於0的數",
      EnterUpTo10Digits: "最多輸入30位",
      AmountRecoveredSuccessfully: "分配金額成功",
      PleaseSelectStation: "請選擇電站",
      Recyclable: "最多可回收(HK$)",
      Second: "秒",
      PleaseEnterEmail: "請輸入郵箱",
      PleaseEnterUserName: "請輸入用戶名",
    },
    dashboard: {
      displayName: "首頁",
      dataOverview: "數據概覽",
      chargingCapacityToday: "今日充電數據",
      chargingOrder: "充電訂單",
      statisticsAreAsOfTheCurrentUpdateTime: "統計數據為截止當前更新時間",
      totalPowerChargedToday: "今日充電（度）",
      chargingFee: "充電電費（HK$）",
      serviceFee: "充電功率費（HK$）",
      chargingAmount: "充電金額（HK$）",
      numberOfCharging: "充電次數（次）",
      chargingCapacityTodayChart: "今日充電量",
      chargingCapacityYesterdayChart: "昨日充電量",
      cashData: "資金數據",
      fleetAccountBalance: "車隊賬戶餘額（HK$）",
      accountBalance: "司機賬戶餘額（HK$）",
      userData: "用戶數據",
      chargingManagement: "充電管理",
      numberOfChargingUsers: "充電司機數",
      numberOfChargingVehicles: "充電車輛數",
    },
    administratorTable: {
      administratorAccount: "管理員賬號",
      administratorName: "管理員姓名",
      accountStatus: "賬號狀態",
      role: "角色",
      updateTime: "修改時間",
      confirmResetPassword: "確認重置密碼？",
      resetPassword: "重置密碼",
      yes: "是",
      no: "否",
      confirm: "確認",
      unlock: "解鎖",
      confirmUnlock: "確認解鎖？",
      delete: "刪除",
      confirmDelete: "確認操作？",
      enable: "啟用",
      disable: "禁用",
      superAdmin: "超級管理員",
      normalAdmin: "普通管理員",
      normal: "正常",
      add: "添加",
      default: "默認",
      Cancel: "取消",
    },
    carInfo: {
      stationSelfCoding: "自編碼",
      addCar: "新增車輛",
      notice:
        "特別說明：部分車輛或充電樁不支持獲取VIN碼，實際充電訂單可能獲取不到車輛信息。",
    },
    account: {
      account: "賬號",
      userName: "用戶名",
      accountType: "賬戶類型",
      accountStatus: "賬戶狀態",
      addAccount: "添加賬戶",
      balance: "賬戶餘額",
      operator: "操作人",
      operateTime: "操作時間",
      chargingSetting: "充電設置",
      more: "更多",
      edit: "編輯",
      delete: "刪除",
      recycle: "回收",
      recycleTip: "當前沒有可回收的餘額",
      distribute: "分配",
      disable: "禁用",
      UserName: "用戶名",
      enable: "啟用",
      Stopping: "正在停止",
      normal: "正常",
      PleaseCompleteTheInformation: "請補全信息",
    },
    invoiceApply: {
      chooseCompany: "選擇企業",
      apply: "選擇企業",
      invoiceDetail: "開票內容",
      paymentTime: "支付時間",
      stationName: "電站名稱",
      invoiceHistory: "開票歷史",
      invoiceCompany: "開票企業",
      invoiceAmount: "可開票金額(HK$)",
    },
    chargingBill: {
      billDate: "賬單生成日",
      addBill: "添加賬單",
      billPeriod: "賬單週期",
      name: "運營商名稱",
      electricityPrice: "電費",
      billTime: "賬單生成時間",
      operator: "操作人",
      serviceFee: "功率費",
      chargingVolume: "充電量",
      totalAmount: "總金額",
      orderDetail: "賬單明細",
      unit: "度",
      // bill,
    },
    changeLog: {
      changeDate: "變動日期",
      typeOfChange: "變動類型",
      subjectOfChange: "變動主體",
      summary: "週期內金額匯總（HK$）",
      serialNumber: "交易流水號",
      operator: "操作人賬號",
      operateTime: "變動時間",
      changeAmount: "變動金額(HK$)",
      currentBalance: "當前餘額(HK$)",
      affiliatedCompany: "所屬企業",
      UberCar: "網約車",
      Bus: "公交車",
      RentalCar: "分時租賃",
      LogisticsExpress: "物流快遞",
      Passengerbus: "客運大巴",
      Carrental: "汽車租賃",
      MainEngineFactory: "主機廠",
      SmallBusiness: "小公司企業",
      GovernmentRelated: "政府相關",
      WanBangInternal: "萬幫內部",
    },
    occupation: {
      timeUnit: "分鐘",
      priceUnit: "HK$",
      stationName: "電站名稱",
      userAccount: "用戶賬號",
      searchOrder: "訂單搜索",
      startTime: "佔位開始時間",
      chargingDevice: "充電應用",
      gunNumber: "充電插頭編號",
      orderStatus: "訂單狀態",
      complaintStatus: "申訴狀態",
      chargingInfo: "充電信息",
      occupationInfo: "佔位信息",
      paymentStatus: "付款信息",
      status: "狀態",
      operation: "操作",
      enterpriseName: "企業名稱",
      transactionID: "訂單號",
      billingPrice: "計費價格",
      maximumAmount: "上限金額",
      paymentTime: "支付時間",
      chargingAPP: "充電應用",
      occupationTimeDuration: "佔位時長",
      payableAmount: "應付金額",
      chargingTimeDuration: "充電時間",
      freeDuration: "免費時長",
      amountPaid: "實付金額",
      occupationTime: "佔位時間",
      billingTimeDuration: "計費時長",
      viewClaims: "查看申訴",
      chargingTransaction: "充電訂單",
      billingAmount: "計費金額",
      complain: "發起申訴",
      Discount: "功率費折扣",
      FixedServiceFee: "固定功率費",
      FixedTotalPrice: "固定總價",
      ServiceChargeFixed: "功率費按電費固定比例",
      ServiceFeeDiscount: "功率費折扣",
      ElectricityDiscount: "電費折扣",
      TimePeriodDiscount: "分時段折扣",
      FixedDiscountForElectricityAndServiceCharges: "電費、功率費固定值折扣",
      unit: "HK$/度",
      peak: "峰",
      tip: "尖",
      usually: "谷",
      vec: "平",
    },
    chargingTransaction: {
      stationName: "電站名稱",
      endTimeOfCharging: "充電結束時間",
      today: "今",
      yesterday: "昨",
      nearly7Days: "近7日",
      nearly30Days: "近30日",
      paymentType: "支付類型",
      paymentStatus: "支付狀態",
      all: "全部",
      personalPayment: "個人支付",
      personal: "個人",
      Enterprise: "企業",
      fleetPayment: "EV車隊支付",
      partnerPayment: "互聯互通支付",
      creditCardPayment: "卡平台支付",
      paid: "已支付",
      unpaid: "未支付",
      userAccount: "用戶賬號",
      enterpriseName: "企業名稱",
      vehicleInformation: "車輛信息",
      SelectVehicle: "選擇車輛",
      VINCode: "VIN碼",
      plateNumber: "車牌號",
      connectorID: "充電插頭編號",
      startReason: "啟動方式",
      chargingStatus: "充電狀態",
      transactionNumber: "訂單號",
      transactionStatus: "訂單狀態",
      stopReason: "停止原因",
      highRiskModels: "高危車型",
      // stationName: "电站名称",
      // transactionNumber: "订单号",
      // connectorID: "枪编号",
      startChargingTime: "充電開始時間",
      initialSOC: "初始SOC",
      transactionChargingVolume: "訂單電量(度)",
      startMethod: "啟動方式",
      endChargingTime: "充電結束時間",
      endSOC: "結束SOC",
      originalElectricityPrice: "電費原價(HK$)",
      userName: "用戶姓名",
      originalServicePrice: "功率費原價(HK$)",
      totalFeeOfTheTransaction: "訂單總金額(HK$)",
      transactionDetails: "訂單詳情",
      chargingProcess: "充電過程",
      AppletStartup: "小程序啟動",
      PowerCardStart: "電卡啟動",
      OnlineVin: "在線VIN",
      AppStartup: "App啟動",
      charging: "充電中",
      ChargingCompleted: "充電完成",
      SharedAccount: "共享賬戶",
      IndependentAccount: "獨立賬戶",
      submit: "提交",
      UnbindingSucceeded: "解綁成功",
      UnbindVehicle: "解綁車輛",
      AreUouSureYouWantToUnbind: "確定要解綁嗎？",
      ChargingStation: "充電電站",
      UnlimitedStation: "不限電站",
      DesignatedStation: "指定電站",
      ChargingPeriod: "充電時段",
    },
    baseInfo: {
      basicInformation: "基礎信息",
      invoiceInformation: "開票信息",
      fleetName: "車隊名稱",
      ownerName: "法人姓名",
      ownerMobile: "法人手機號",
      identificationNumber: "證件號碼",
      useOfVehicle: "車輛類型",
      permissionOfStation: "電站權限",
      detail: "點擊查看",
      invoiceType: "開票類型",
      invoiceTitle: "發票抬頭",
      accountInformation: "賬戶信息",
      statisticsAreAsOfTheCurrentUpdateTime: "統計數據為截止當前更新時間",
      balance: "賬戶餘額（HK$）",
      changeLog: "變動記錄",
      account: "賬號信息",
      adminAccount: "後台管理員賬號",
      switch: "更換賬號",
      adminName: "管理員姓名",
      status: "賬號狀態",
      fleetMember: "充電成員",
      numberOfDrivers: "已有充電司機數",
      numberOfVehicles: "已有充電車輛數",
      ChangeAdministratorAccount: "更換管理員賬號",
      CurrentMobileNumber: "當前手機號",
      CurrentAccountVerificationCode: "當前賬號驗證碼",
      NewMobileNumber: "新手機號",
      phoneTips1: "手機號已更換為",
      phoneTips2: "秒後重新獲取",
      phoneTips3: "輸入新手機號收到的短信驗證碼",
      PleaseLoginAgain: "請重新登錄",
      LoginAgain: "重新登錄",
      phoneTips4: "請輸入新的手機號",
      phoneTips5: "輸入當前手機號收到",
      NewMobileNumberverificationCode: "新手機號驗證碼",
    },
    VehicleInformation: {
      SelfEncoding: "自編號",
      // DriverAccount: "所属司机账户",
      TimeModified: "變動時間",
      ModifyInformation: "修改信息",
      delete: "刪除",
      NumberPlate: "車牌號",
      VinCode: "VIN碼",
      SpecialInstructions:
        "特別說明：部分車輛或充電樁不支持獲取VIN碼，實際充電訂單可能獲取不到車輛信息。",
      singleNew: "單個新增",
      batchNew: "批量新增",
      newWay: "新增方式",
      AddOrEditVehicles: "新增/編輯車輛",
      PleaseEnterLicensePlateNumber: "請輸入車牌號",
      PleaseEnterVINCode: "請輸入VIN碼",
      UploadFailList: "上傳失敗列表",
      DriverInformation: "司機信息",
      DriverAccount: "司機賬號",
      PleaseEnterDriverAccount: "請輸入司機賬號",
      PleaseEnterOneUserName: "請輸入用戶名",
      tips1: "1、下載模板，將車輛信息填寫到模板中",
      tips2: "僅支持選擇已錄入車輛。",
      tips3: "當前司機有正在進行的訂單，保存的信息將在訂單完成後生效",
      tips4:
        "切換成共享賬戶後，獨立賬戶下的資金不會主動回收，請注意及時回收資金",
      tips5: "當前電卡已有司機賬戶，無法再次添加。",
      tips6: "當前車輛已有司機賬戶，無法再次添加。",
      tips7: "起始時間不能大於結束時間",
      tips8: "時間段不能重複，請檢查您的時間段",
      tips9: "時間區間不能有交叉重合",
      tips10: "如司機當前正在充電，保存設置將在訂單完成後自動生效",
      tips11: "請填寫新的管理員賬號",
    },
    chargingStation: {
      StationName: "電站名稱",
      AffiliatedCompany: "所屬企業",
      AccountManagerName: "客戶經理",
      AccountManagerMobileNumber: "客戶經理手機號",
      StationAddress: "電站地址",
      DCcharger: "直流設備",
      ExclusiveDiscount: "專屬折扣",
      DiscountEffectiveTime: "生效時間",
      serviceHours: "服務時間",
      ACcharger: "交流設備",
      DiscountTypeValue: "折扣值",
      TransactionNotification: "事務通知",
      Unread: "未讀",
      NoMessageContent: "暫無消息內容",
      hoursAgo: "小時前",
      minutesAgo: "分鐘前",
      secondsAgo: "秒前",
      NoDownloadLink: "暫無下載鏈接",
      PleaseCloseExtraWindows: "請關閉多餘窗口!",
      MarkRead: "標記已讀",
    },
    ElectricityCard: {
      cardNumber: "卡號",
      BindingSubject: "綁定主體",
      BindingState: "綁定狀態",
      TheElectricCardStatus: "電卡狀態",
      AddTheElectricCard: "添加電卡",
      BatchAdd: "批量添加",
      disable: "禁用",
      delete: "刪除",
      TimeModified: "變動時間",
      enable: "啟用",
      UploadFailureList: "上傳失敗列表",
      ClickDownload: "點擊下載",
      downloadStep1: "下載模板，將電卡信息填寫到模板中；",
      downloadStep2: "將填寫好的模板上傳。",
      UploadFile: "上傳文件",
      DownloadTemplate: "下載模板",
      ElectricCardInfo: "電卡信息",
      SelectElectricCard: "選擇電卡",
      UnbindElectricCard: "解綁電卡",
      ElectricCardNumber: "電卡卡號",
    },
    orderDetail: {
      orderDetail: "訂單詳情",
      carselfcoding: "車輛自編碼",
      LengthTime: "充電時長",
      InitialSOC: "初始SOC",
      EndSOC: "結束SOC",
      Timeofpayment: "支付時間",
      MethodOfPayment: "支付方式",
      Amountplatform: "應付金額(HK$)",
      Discountamount: "折扣金額(HK$)",
      Discounttype: "折扣類型",
      actuallyPaid: "實付金額(HK$)",
      ExporSrder: "導出訂單",
      OrderTime: "訂單時間",
      CollatingTableDateExport: "正在整理要導出的表格數據，請稍後...",
      YouCanLeaveThisPage:
        "您可以離開此頁面，導出將在後台進行，完成後請到“通知”進行下載。",
      Ok: "知道了",
    },
    viewcomplaint: {
      viewcomplaint: "查看申訴",
      Complaintdescription: "申訴說明",
      Complainpicture: "申訴圖片",
    },
    billDetail: {
      exportStatement: "導出報表",
      orderNO: "訂單編號",
      powerStationName: "電站名稱",
      userAccount: "用戶賬號",
      chargingAmount: "充電量",
      OriginalElectricityPrice: "電費原價",
      Connectornumber: "充電插頭編號",
      licenseNumber: "車牌號",
      actuallyPaid: "實付金額",
      chargingTime: "充電時間",
    },
    VINPermissions: {
      whetherEnable: "是否開啟Vin碼鑑權",
      No: "不開啟",
      Yes: "開啟",
      Instructions: "說明",
      tips:
        "vin碼鑑權開關開啟時，員工使用企業賬戶充電時，會校驗當前車輛是否綁定，如果沒有綁定無法啟動，請使用個人賬戶啟動。",
      msg:
        "vin碼鑑權開關關閉時，員工使用企業賬戶充電，不進行車輛校驗，有餘額則可以正常啟動。",
      Save: "保存",
    },
    Employee: {
      Username: "用戶名",
      MobilePhoneNumber: "手機號",
      ReportType: "報表類型",
      Date: "日期",
      NumberOfCharging: "充電次數",
      ChargingDuration: "充電時長",
      Search: "查詢",
      Export: "導出",
      Totalchargingamount: "總充電量（度）",
      Chargingcost: "充電費用（HK$）",
      Electricityfee: "充電電費（HK$）",
      Servicefee: "充電功率費（HK$）",
      ElectricQuantityatshoulderhour: "充電平時電量（度）",
      ElectricityFeeatshoulderhour: "充電平時電費（HK$）",
      ServiceFeeatshoulderhour: "充電平時功率費（HK$）",
      ElectricQuantityatsharphour: "充電尖時電量（度）",
      ElectricityFeeatsharphour: "	充電尖時電費（HK$）",
      ServiceFeeatsharphour: "充電尖時功率費（HK$）",
      ElectricQuantityatpeakhour: "充電峰時電量（度）",
      ElectricityFeeatpeakhour: "充電峰時電費（HK$）",
      ServiceFeeatpeakhour: "充電峰時功率費（HK$）",
      ElectricQuantityatoffpeakhour: "充電谷時電量（度）",
      ElectricityFeehour: "充電谷時電費（HK$）",
      ServiceFeehour: "充電谷時功率費（HK$）",
      LicenseplateNo: "車牌號",
      Vehiclecode: "車輛編碼",
      date: "日报",
      month: "月报",
      year: "年报",
      other: "旬报",
      other1: "上旬",
      other2: "中旬",
      other3: "下旬",
      self: "自定义时间",
      monthSelect: "月份选择",
      yearSelect: "年份选择",
      otherSelect: "月旬选择",
      selfSelect: "范围选择",
    },
  },
};
