import moment from "moment";
import { parse } from "querystring";
/* eslint no-useless-escape:0 import/prefer-default-export:0 */
// 全局挂载class设置
const appPackage = require("../../package.json");
const appGlobalClass = `.sc-app-${appPackage.name}`;
// 分页参数全局设置
export const currentPageKey = "page";
export const pageSizeKey = "pagecount";

// 获取/设置sessionStorage参数
const { APP_NAME, APP_VERSION } = process.env;
const APP_STORAGE_PFIX = `${APP_NAME}_${APP_VERSION}_`;
export const getStorage = (key) => {
  return window.sessionStorage.getItem(`${APP_STORAGE_PFIX}${key}`);
};
export const setStorage = (key, value) => {
  return window.sessionStorage.setItem(`${APP_STORAGE_PFIX}${key}`, value);
};

const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = (path) => reg.test(path);
export const getPageQuery = () => parse(window.location.href.split("?")[1]);

/**
 * 格式化数字
 * @param {*} num 原数据
 * @param {*} len 保留几位小数
 * @param {*} isQ 是否千分位格式化
 */
export const formatNum = (num, len = 0, isQ = false) => {
  if (+num >= 0) {
    const arr = (+num).toFixed(len).split(".");
    if (isQ) {
      return len === 0
        ? (+arr[0]).toLocaleString()
        : `${(+arr[0]).toLocaleString()}.${arr[1]}`;
    } else {
      return len === 0 ? arr[0] : `${arr[0]}.${arr[1]}`;
    }
  }
  return "--";
};

/**
 * UUID生成
 */
export const UUID = () => {
  let d = new Date().getTime();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-bitwise
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

/**
 * 从数组创建扁平化数据
 * @param {array} arrIns 待扁平化数组
 * @param {*} subKey 扁平化对象key值
 * @param {*} compare 判断放置函数
 */
export const flatMapWith = (arrIns, subKey = "children", compare = true) => {
  const flatMap = [];
  flatArray(arrIns);
  function flatArray(list) {
    list.forEach((el) => {
      if (compare === true || compare(el)) {
        flatMap.push(el);
      }
      if (el[subKey] && el[subKey].length) {
        flatArray(el[subKey]);
      }
    });
  }
  return flatMap;
};

// 更改挂载节点
export const getPopupContainer = () => document.querySelector(appGlobalClass);

// 更改挂载节点
export const getContainer = () => document.querySelector(appGlobalClass);

// 表单节点统一挂载
export const formItemAdapterProps = {
  getPopupContainer,
};

// 全局方法挂载点
export const contextHolderApi = {
  message: null,
  modal: null,
};

/**
 * 对表单原始数据进行处理
 * @param {*} values
 * @param {object} formatRule 对特定字段进行格式化 {key:"YYYY-MM-DD"}
 */
export const handleOrignalFormData = (values, formatRule) => {
  const keys = Object.keys(values);
  keys.forEach((key) => {
    // 处理区间时间
    if (key.indexOf("$$") > -1) {
      const array = key.split("$$");
      const format = array[2].split("##");
      values[array[0]] =
        (values[key] && values[key][0].format(format[0])) || undefined;
      values[array[1]] =
        (values[key] && values[key][1].format(format[1])) || undefined;
      delete values[key];
    }
    // 处理普通时间
    if (values[key] instanceof moment) {
      const $formatRule =
        (formatRule && formatRule[key]) || "YYYY-MM-DD HH:mm:ss";
      values[key] = values[key].format($formatRule);
    }
  });
  return values;
};
/**
 * 导出下载文件
 * @param {*} url
 * @param {*} params
 */
export const exportFileByForm = (url, searchJson, fileName) => {
  const formElement = document.createElement("form");
  formElement.style = "display:none;";
  formElement.method = "post";
  formElement.action = `${process.env.APP_API_URL_ZH}${url}`;
  formElement.target = "callBackTarget";

  if (fileName) {
    const inputElement = document.createElement("input");
    inputElement.type = "hidden";
    inputElement.name = "fileName";
    inputElement.value = fileName;
    formElement.appendChild(inputElement);
  }

  if (searchJson) {
    const inputElement = document.createElement("input");
    inputElement.type = "hidden";
    inputElement.name = "searchJson";
    inputElement.value = JSON.stringify(searchJson);
    formElement.appendChild(inputElement);
  }
  document.body.appendChild(formElement);
  formElement.submit();
  document.body.removeChild(formElement);
};

/**
 * 取得模板下载地址
 * @param {string} templateName 模板名称  xxx.xlsx
 * @returns urlString 模板下载地址
 */
export const getTemplateUrl = (templateName) => {
  const { APP_OSS_URL } = process.env;
  return `${APP_OSS_URL}emc_trade/${templateName}`;
};

/**
 * 键盘回车事件
 * @param e
 * @param callback
 */
export const keydownEnter = (e, callback) => {
  e.stopPropagation();
  const theEvent = window.event || e;
  const code = theEvent.keyCode || theEvent.which || theEvent.charCode;
  if (code === 13) {
    e.preventDefault();
    callback(e);
  }
};

export const numberFormat = (text, fixed, unit, per, defaultRV = 0) => {
  if (!fixed && fixed !== 0) {
    fixed = 2;
  }
  let result = defaultRV;
  if (text || Math.floor(text) === 0) {
    text = Number(text);
    const arr = text.toFixed(fixed).split(".");

    const fixedNum = arr[1] ? `.${arr[1]}` : "";

    result = `${arr[0].replace(
      /(d{1,3})(?=(d{3})+(?:$|.))/g,
      "$1,"
    )}${fixedNum}`;

    // 如果需要转换单位，需要满足当前值 > 转换基准值
    if ((unit || unit === "") && per && text >= per) {
      result = `${(text / per).toFixed(fixed)}${unit}`;
    }
  }
  return result;
};

export const ChangeHourMinutestr = (str) => {
  if (str !== "0" && str !== "" && str !== null) {
    return (
      (Math.floor(str / 60).toString().length < 2
        ? "0" + Math.floor(str / 60).toString()
        : Math.floor(str / 60).toString()) +
      "时" +
      ((str % 60).toString().length < 2
        ? "0" + (str % 60).toString() + "分"
        : (str % 60).toString() + "分")
    );
  } else {
    return "";
  }
};

/**
 *
 * @param code
 * @param name
 */
export const loadCssCode = (code, name) => {
  const style = document.createElement("style");
  style.type = "text/css";
  style.rel = "stylesheet";
  style.setAttribute("name", name);
  style.appendChild(document.createTextNode(code));

  const head = document.getElementsByTagName("head")[0];
  head.appendChild(style);
};

/**
 * 删除css代码片段
 * @param name
 */
export const removeCssCode = (name) => {
  const styles = Array.from(document.getElementsByTagName("style"));

  const target = styles.filter((v) => v.getAttribute("name") === name);

  if (target && target.length > 0) {
    const head = document.getElementsByTagName("head")[0];
    head.removeChild(target[0]);
  }
};

/**
 *
 * @param text
 * @param format
 * @returns {string}
 */
export const translateTime = (text, format = "YYYY-MM") => {
  const arr = Array.from(text);
  // 正则匹配替换
  return format.replace(/[YMDHms]/g, () => {
    return arr.shift();
  });
};

/**
 * 设置antd内部元素的tabindex
 * @param className
 * @param index
 * @param setAll
 */
export const setTabIndex = (className, index, setAll) => {
  const elements = document.getElementsByClassName(className);
  if (!setAll) {
    const ele = elements[0];
    if (ele) {
      ele.setAttribute("tabindex", index);
    }
    return;
  }
  Array.from(elements).forEach((v) => {
    v.setAttribute("tabindex", index);
  });
};

/**
 * 跳过键盘聚焦
 * @param className
 */
export const cancelTabIndex = (className) => {
  const elements = document.getElementsByClassName(className);

  Array.from(elements).forEach((v) => {
    v.setAttribute("tabindex", -1);
  });
};

/**
 *
 * 判断一天中多段时间是否有交集
 */
export const isHasRepeatTime = (data) => {
  const startTimeArr = [];
  const endTimeArr = [];
  (data || []).map(function (item) {
    startTimeArr.push(item.startPeriod);
    endTimeArr.push(item.endPeriod);
  });
  const allStartTime = startTimeArr.sort();
  const allEndTime = endTimeArr.sort();
  let result = 0;
  for (let k = 1; k < allStartTime.length; k++) {
    if (allStartTime[k] < allEndTime[k - 1]) {
      result += 1;
    }
  }
  return result > 0;
};

/**
 * 时间戳转化
 */
export const FormateTime = (time) => {
  if (typeof time === "string") {
    return time;
  }

  if (time instanceof moment) {
    return time.format("YYYY-MM-DD HH:mm:ss");
  }
  return time;
};
/**
 * 获取url参数
 * @param paramName
 * @returns {string|null}
 */
export const getUrlParam = (paramName) => {
  const paramUrl = window.location.href.split("?")[1];
  let [paramValue, isFound] = ["", !1];
  if (!paramUrl) {
    return null;
  }
  if (paramUrl.indexOf("=") > 1) {
    const arrSource = paramUrl.split("&");
    let i = 0;
    while (i < arrSource.length && !isFound) {
      const item = arrSource[i];
      if (
        item.includes("=") &&
        item.split("=")[0].toLowerCase() === paramName.toLowerCase()
      ) {
        paramValue = decodeURIComponent(item.split("=")[1]);
        isFound = !0;
      }
      i++;
    }
  }
  if (paramValue === "") {
    paramValue = null;
  }
  return paramValue;
};
