import request from "@/utils/request";

/**
 * 获取首页数据
 * @param params
 * @returns {Promise<any>}
 */
export async function getHomeDataSource(params) {
  return request.get("/mtsV2/getIndex", { params });
}
