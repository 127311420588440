import request from "@/utils/request";

/**
 * 获取占位订单列表
 * @param params
 * @returns {Promise<any>}
 */
export async function getParkingOrder(params) {
  return request.get("/mtsV2/parkingOrder/list", { params });
}

/**
 * 占位申诉详情
 * @param params
 * @returns {Promise<any>}
 */
export async function parkingOrderAppealDetail(params) {
  return request.get("/mtsV2/parkingOrder/appealDetail", { params });
}

/**
 * 占位订单申诉
 * @param params
 * @returns {Promise<any>}
 */
export async function parkingOrderAppeal(params) {
  return request.post("/mtsV2/parkingOrder/appeal", { params });
}

/**
 * 占位订单导出
 * @param params
 * @returns {Promise<any>}
 */
export async function placeHolderOrderExport(params) {
  return request.post("/mtsV2/placeHolderOrder/Export", { params });
}
/**
 * 获取车队所有可充充电场站
 * @param params
 * @returns {Promise<any>}
 */
export async function getAvailableStationByFleet(params) {
  return request.get("/mtsV2/ignore/findStationInfo/byFleetId", { params });
}
