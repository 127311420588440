import { getHomeDataSource } from "./service";

const Model = {
  namespace: "homeModel",
  state: {
    dataSource: {},
    pointer: 0,
  },
  effects: {
    /**
     * 获取首页数据源
     * @param payload
     * @param call
     * @param put
     * @returns {Generator<*, void, *>}
     */
    *getData({ payload }, { call, put }) {
      const res = yield call(getHomeDataSource, payload);

      if (res && res.code === "200") {
        yield put({
          type: "setData",
          payload: res.data,
        });
      }
    },
  },
  reducers: {
    setData(state, { payload }) {
      return { ...state, dataSource: payload };
    },

    setPointer(state, { payload }) {
      return { ...state, pointer: payload };
    },
  },
};
export default Model;
