import request from "@/utils/request";

/**
 * 获取电卡列表
 * @param params
 * @returns {Promise<any>}
 */
export async function getCardList(params) {
  return request.get("/mtsV2/getCardList", { params });
}

/**
 * 新增电卡
 * @param params
 * @returns {Promise<any>}
 */
export async function addCard(params) {
  return request.post("/mtsV2/addCard", { params });
}

/**
 * 新增电卡
 * @param params
 * @returns {Promise<any>}
 */
export async function batchAddCard(params) {
  return request.post("/mtsV2/batchAddCard", { params });
}

/**
 * 启用电卡
 * @param params
 * @returns {Promise<any>}
 */
export async function enabledCardState(params) {
  return request.post("/mtsV2/disableCard", { params });
}

/**
 * 禁用电卡
 * @param params
 * @returns {Promise<any>}
 */
export async function disabledCardState(params) {
  return request.post("/mtsV2/disableCard", { params });
}

/**
 * 导出电卡excel
 * @param params
 * @returns {Promise<any>}
 */
export async function exportCard(params) {
  return request.post("/mtsV2/exportCardExcel", { params });
}

/**
 * 删除电卡
 * @param params
 * @returns {Promise<any>}
 */
export async function deleteCard(params) {
  return request.post("/mtsV2/delCard", { params });
}
