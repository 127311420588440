import React from "react";

import sessionManager from "@/utils/sessionStorage";
import en_US from "antd/lib/locale/en_US";
import zh_CN from "antd/lib/locale/zh_CN";
import zh_HK from "antd/lib/locale/zh_HK";
import EN_US from "./locales/en-US";
import ZH_CN from "./locales/zh-CN";
import ZH_HK from "./locales/zh-HK";

const languageMap = [
  { name: "English", value: "en-US", payload: EN_US, antdLang: en_US },
  { name: "中文", value: "zh-CN", payload: ZH_CN, antdLang: zh_CN },
  { name: "中文繁體", value: "zh-HK", payload: ZH_HK, antdLang: zh_HK },
];
const sysLang = EN_US;
const LangContext = React.createContext({
  getLang: sysLang,
  setLang: () => {
    return;
  },
});
const getLanguageStorage = () => {
  const langKey = sessionManager.getLasting("local") || "zh-CN";
  return languageMap.find((item) => item.value === langKey);
};

const getServiceLocalCode = () => {
  const locale = sessionManager.getLasting("local");
  const map = new Map([
    ["zh-CN", "zh"],
    ["en-US", "en"],
    ["zh-HK", "hk"],
  ]);
  return map.get(locale);
};

// LangContext.Provider
export { languageMap, LangContext, getLanguageStorage, getServiceLocalCode };
