import {
    queryChangeEnum,
    queryChangeTotal
} from '@/pages/financial/changeRecord/service';

const Model = {
    namespace: 'changeRecordModel',
    state: {
        sum: null,
        enumList: []
    },
    effects: {
        /**
         * 变动类型
         * @param payload
         * @param call
         * @param put
         * @returns {Generator<*, void, *>}
         */
        *getEnum({ payload }, { call, put }) {
            const res = yield call(queryChangeEnum, payload);

            if (res && res.code === '200') {
                yield put({
                    type: 'setState',
                    payload: {
                        enumList: [...res.data]
                    }
                });
            }
        },
        /**
         * 获取汇总数据
         * @param payload
         * @param call
         * @param put
         * @returns {Generator<*, void, *>}
         */
        *getSum({ payload }, { call, put }) {
            const res = yield call(queryChangeTotal, payload);

            if (res && res.code === '200') {
                yield put({
                    type: 'setState',
                    payload: {
                        sum: res.data
                    }
                });
            }
        }
    },
    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        }
    }
};
export default Model;
