import request from "@/utils/request";

/**
 * 查询管理员列表
 * @param params
 * @returns {Promise<any>}
 */
export async function queryAdministratorList(params) {
  return request.post("/mtsV2/admin/list", { params });
}

/**
 * 重置密码
 * @param params
 * @returns {Promise<any>}
 */
export async function resetPassword(params) {
  return request.post("/mtsV2/admin/reset", { params });
}

/**
 * 启用账号
 * @param params
 * @returns {Promise<any>}
 */
export async function enabledAdminAccount(params) {
  return request.post("/mtsV2/admin/enable", { params });
}

/**
 * 禁用账号
 * @param params
 * @returns {Promise<RequestResponse<any>>}
 */
export async function disabledAdminAccount(params) {
  return request.post("/mtsV2/admin/disable", { params });
}

/**
 * 新增子管理员
 * @param params
 * @returns {Promise<any>}
 */
export async function addChildAdmin(params) {
  return request.post("/mtsV2/admin/add", { params });
}

/**
 * 删除子管理员
 * @param params
 * @returns {Promise<any>}
 */
export async function deleteChildAdmin(params) {
  return request.post("/mtsV2/admin/delete", { params });
}

export async function unlockAccount(params) {
  return request.get("/mtsV2/ignore/unLockAccount", { params });
}
