/**
 * 同步导出挂载全局的方法
 */
export default (funcName) => {
  // eslint-disable-next-line global-require
  const { contextHolderApi } = require("@/utils/utils");
  if (contextHolderApi[funcName]) {
    return contextHolderApi[funcName];
  } else {
    throw new Error(`Can't find '${funcName}'!`);
  }
};
