import { parkingOrderAppeal, parkingOrderAppealDetail } from "@/pages/order/seize/service";

const Model = {
  namespace: "seizeModel",
  state: {
    record: {},
    detail: {},
  },
  effects: {
    *patch({ payload }, { put }) {
      yield put({
        type: "setState",
        payload,
      });
    },
    /**
     * 修改车辆信息
     * @param payload
     * @param call
     * @param put
     * @returns {Generator<*, void, *>}
     */
    *appeal({ payload }, { call, put }) {
      const res = yield call(parkingOrderAppeal, payload);
      if (res && res.code === "200") {
        yield put({
          type: "setState",
          payload: {
            editVisible: false,
          },
        });
        return true;
      }
      return false;
    },

    *getDetail({ payload }, { call, put }) {
      const res = yield call(parkingOrderAppealDetail, payload);
      if (res && res.code === "200") {
        yield put({
          type: "patch",
          payload: {
            detail: res.data,
          },
        });
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
export default Model;
