export default {
  validator: {
    bankNo: "Please enter the correct bank card number",
    idNo: "Please input the correct ID number",
    phoneRequired: "Please enter the correct mobile phone number",
    phoneDigit: "Please enter 11 valid mobile phone numbers!",
    idNoDigit: "Please enter a valid 18 digit number!",
    idNoDigitPlus: "Please enter a valid number of 9 or 18 digits!",
    vin: "Vin code must be 17 valid characters!",
    common: "Only numbers, letters and Chinese characters are supported!",
    eCard: "The number of electric cards cannot be greater than 10",
    digit: "Decimal point precision 2 digits!",
    password:
      "The password must be a combination of upper and lower case alphanumeric characters, special characters, and greater than 12 digits",
    timeRequired: "Please select a time",
    email: "Please enter the correct mailbox",
  },
  tip: {
    noLink: "No Download Link",
    batchAddSuccess: "Batch addition succeeded",
  },
};
