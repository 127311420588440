export default {
  validator: {
    bankNo: "請輸入正確的銀行卡號",
    idNo: "請輸入正確的身份證號",
    phoneRequired: "請輸入正確的手機號碼",
    phoneDigit: "請輸入11位有效手機號碼！",
    idNoDigit: "請輸入18位有效號碼！",
    idNoDigitPlus: "請輸入9位或18位有效號碼！",
    vin: "VIN碼必須為17位有效字符！",
    common: "僅支持輸入數字字母和漢字！",
    eCard: "電卡位數不能大於10位",
    digit: "小數點精度2位！",
    password: "密碼必須由大小寫字母、數字、特殊字符組合併且大於12位",
    timeRequired: "請選擇時間",
    email: "請輸入正確的郵箱",
  },
  tip: {
    noLink: "暫無下載鏈接",
    batchAddSuccess: "批量新增成功",
  },
};
