/**
 * 管理WEB应用缓存
 * @type {{clearAll: sessionManager.clearAll, clearItem: sessionManager.clearItem, get: sessionManager.get, set: sessionManager.set}}
 */
export default {
  /**
   * 清除应用所有缓存
   */
  clearAll() {
    const companyConfig = sessionStorage.getItem("COMPANY_CONFIG");
    sessionStorage.clear();
    if (companyConfig) {
      sessionStorage.setItem("COMPANY_CONFIG", companyConfig);
    }
  },

  clearItem(key) {
    sessionStorage.removeItem(key);
    return true;
  },

  clearLastingItem(key) {
    localStorage.removeItem(key);
    return true;
  },

  /**
   * 获得缓存信息
   * @param key
   * @returns {*}
   */
  get: (key) => {
    const d = new Date();
    // eslint-disable-next-line radix
    const currentTime = parseInt(d.getTime()); // millisecond

    const storeData = sessionStorage.getItem(key);
    if (!storeData) {
      return "";
    }

    const storeDataFormat = JSON.parse(storeData);

    if (
      typeof storeDataFormat !== "object" ||
      typeof storeDataFormat.expireTime !== "number"
    ) {
      return false;
    }

    if (
      storeDataFormat.expireTime !== 0 &&
      storeDataFormat.expireTime <= currentTime
    ) {
      sessionStorage.removeItem(key);
      return false;
    }
    return storeDataFormat.saveValue;
  },

  /**
   * 存储缓存数据
   * @param key
   * @param value
   * @param expires
   * @returns {boolean}
   */
  set: (key, value, expires = 7200) => {
    if (typeof value === "undefined" || (!value && value !== 0)) {
      return false;
    }

    const d = new Date();
    // eslint-disable-next-line radix
    const currentTime = parseInt(d.getTime()); // millisecond
    const expireTime = expires === 0 ? 0 : currentTime + expires * 1000;
    const storeData = {
      saveTime: currentTime,
      saveValue: value,
      expireTime,
    };

    sessionStorage.setItem(key, JSON.stringify(storeData));
    return true;
  },

  setLasting: (key, value) => {
    if (typeof value === "undefined" || (!value && value !== 0)) {
      return false;
    }

    const d = new Date();
    // eslint-disable-next-line radix
    const currentTime = parseInt(d.getTime()); // millisecond
    const storeData = {
      saveTime: currentTime,
      saveValue: value,
    };
    localStorage.setItem(key, JSON.stringify(storeData));
    return true;
  },
  getLasting: (key) => {
    const storeData = localStorage.getItem(key);
    if (!storeData) {
      return "";
    }

    const storeDataFormat = JSON.parse(storeData);

    if (typeof storeDataFormat !== "object") {
      return false;
    }

    return storeDataFormat.saveValue;
  },
};
