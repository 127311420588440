const Model = {
  namespace: "InvoiceApplyModal",
  state: {
    address: {},
  },
  effects: {
    *selectAddress({ payload }, { put }) {
      yield put({
        type: "setState",
        payload: {
          address: payload.address,
        },
      });
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default Model;
