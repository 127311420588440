import { getIntl } from "@/.umi/plugin-locale/localeExports";
import { getLoginInfo, getLoginKey, logout } from "@/services/login";
import contextApi from "@/utils/contextApi";
import sessionManager from "@/utils/sessionStorage";
import { stringify } from "querystring";
import { history } from "umi";
const runLoginFailed = (res) => {
  contextApi("message").error(res.text || "登录失败");
};
const passContext = (userInfo) => {
  const lang = sessionManager.getLasting("local");

  if (userInfo.loginFairNum < 10) {
    if (lang === "zh-CN") {
      contextApi("message").error(
        `${userInfo.fairReason === 1 ? "密码" : "验证码"}错误，还剩余${
          10 - userInfo.loginFairNum
        }次重试机会`
      );
    } else {
      contextApi("message").error(
        `Wrong ${
          userInfo.fairReason === 1 ? "password" : "SMS Code"
        }, remaining ${10 - userInfo.loginFairNum} Retry opportunities`
      );
    }
  }
};

const lockContext = () => {
  const lang = sessionManager.getLasting("local");

  if (lang === "zh-CN") {
    contextApi("modal").info({
      title: "您的账号已被锁定",
      content: "请在15分钟后重试，也可以联系车队管理员为您的账号解锁。",
    });
  } else {
    contextApi("modal").info({
      title: "Your account has been locked",
      content:
        "Please try again in 15 minutes, or contact the team administrator to unlock your account.",
    });
  }
};
const Login = {
  namespace: "login",
  state: {
    userInfo: null,
    fleetList: [],
    pane: 1,
    flag: false,
    selectFleetId: null,
    userAccount: null,
  },
  effects: {
    *login({ payload }, { call, put }) {
      const intl = getIntl();
      console.log(intl);
      // 获取登陆token;
      const res = yield call(getLoginKey, payload);
      if (res.code === "200") {
        const userInfo = res.data;

        if (userInfo.fairReason === 1 || userInfo.fairReason === 2) {
          passContext(userInfo);

          if (userInfo.lo === 10) {
            lockContext();
          }
        } else if (userInfo.fairReason === 3) {
          lockContext();
        } else if (userInfo.isFirstLogin) {
          yield put({
            type: "switchPane",
            payload: {
              pane: 3,
              userAccount: userInfo.account,
            },
          });
        } else {
          // 保存用户信息
          window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
          // 获取车队信息
          const fleetInfo =
            JSON.parse(window.sessionStorage.getItem("fleetInfo")) || {};
          // 重写车队信息里的token, 让请求头能读取到token
          fleetInfo.token = userInfo.token;
          // 重新保存fleetInfo
          window.sessionStorage.setItem("fleetInfo", JSON.stringify(fleetInfo));
          // 获取车队绑定的运营商
          const fleets = yield call(getLoginInfo);
          if (fleets.code === "200") {
            yield put({
              type: "handleLoginResInfo",
              payload: {
                userInfo,
                fleetList: fleets.data,
              },
            });
            history.replace("/");
            return false;
          }
          runLoginFailed(res);
        }

        return false;
      }
      runLoginFailed(res);
      return true;
    },

    logout() {
      // 获取车队信息
      const fleetInfo =
        JSON.parse(window.sessionStorage.getItem("fleetInfo")) || {};

      logout({ token: fleetInfo.token });
      history.replace({
        pathname: "/user/login",
        search: stringify({
          redirect: window.location.href,
        }),
      });
    },

    switchPane({ payload }, { put }) {
      put({
        type: "switchPane",
        payload,
      });
    },
  },
  reducers: {
    /**
     * 更改pane, 设置登陆框显示状态
     * @param state
     * @param payload
     * @returns {*&{pane: (number|*)}}
     */
    switchPane(state, { payload }) {
      return { ...state, ...payload };
    },
    /**
     * 处理响应结果
     * @param state
     * @param payload
     */
    handleLoginResInfo(state, { payload }) {
      const lang = sessionManager.getLasting("local");

      const { userInfo, fleetList } = payload;
      if (!fleetList || fleetList.length === 0) {
        if (lang === "zh-CN") {
          contextApi("message").error("当前账号未查询到车队信息");
        } else {
          contextApi("message").error(
            "No fleet information found in current account"
          );
        }

        return state;
      }
      let fleetInfo = fleetList[0];
      // 将车队列表存入缓存
      window.sessionStorage.setItem("fleetList", JSON.stringify(fleetList));
      // 获取当前登录用户上次登录选择的车队
      const SELECT_FLEET = window.localStorage.getItem(
        `SELECT_FLEET_${userInfo.userId}`
      );

      if (SELECT_FLEET) {
        const filterRes = fleetList.filter(
          (v) => v.fleetId === JSON.parse(SELECT_FLEET).fleetId
        );
        if (filterRes[0]) {
          fleetInfo = filterRes[0];
        } else {
          window.localStorage.setItem(
            `SELECT_FLEET_${payload.userInfo.userId}`,
            JSON.stringify(fleetInfo)
          );
        }
      } else {
        window.localStorage.setItem(
          `SELECT_FLEET_${userInfo.userId}`,
          JSON.stringify(fleetInfo)
        );
      }

      // 保存车队信息
      window.sessionStorage.setItem("fleetInfo", JSON.stringify(fleetInfo));

      return {
        ...state,
        userInfo: payload.userInfo,
        fleetList: payload.fleetList,
      };
    },
  },
};
export default Login;
