/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { getServiceLocalCode } from "@/langContext";
import contextApi from "@/utils/contextApi";
import sessionManager from "@/utils/sessionStorage";
import { history } from "umi";
import { extend } from "umi-request";

const codeMessage = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
};

/**
 * 异常处理程序
 */

const errorHandler = (error) => {
  const { response } = error;

  // code === 401 登录失效
  if (error && error.code === "jwt_401") {
    // 做一些清空storage的操作
    sessionManager.clearAll();
    contextApi("message").error("登录失效，请重新登录");
    history.replace("/login");
    return Promise.reject(error);
  }

  if (!response && error.message) {
    // 微服务报错规则
    contextApi("message").error(error.message);
  } else if (response && response.status) {
    // status报错
    const errorText = codeMessage[response.status] || response.statusText;
    contextApi("message").error(`请求错误 ${errorText}`);
  } else if (Number(error.code) === 400) {
    // code返回400直接提示后台传回的error.text
    if (error.text) {
      contextApi("message").error(`${error.text}`);
    } else {
      contextApi("message").error("服务异常");
    }
    return Promise.resolve(error);
  } else {
    if (error.text) {
      contextApi("message").error(`${error.text}`);
    } else {
      contextApi("message").error("服务异常");
    }
  }
  return Promise.reject(error);
};

/**
 * 配置request请求时的默认参数
 */

// 子应用接入时不发送cookie避免cors问题
// eslint-disable-next-line no-underscore-dangle
const credentials = window.__POWERED_BY_QIANKUN__
  ? {}
  : { credentials: "include" };
const request = extend({
  errorHandler,
  // 默认错误处理
  ...credentials, // 默认请求是否带上cookie
});

/**
 * 请求前处理
 */
request.interceptors.request.use((url, options) => {
  const formatterUrl = process.env.APP_API_URL + url;
  // 判断是否存在token，如果存在的话，则每个http header都加上token
  const fleetInfo =
    JSON.parse(window.sessionStorage.getItem("fleetInfo")) || {};

  let menuId = sessionStorage.getItem("CURRENT_MENU_KEY");

  if (url.indexOf("mtsV2/logout") > -1) {
    menuId = "";
  }
  options.params = {
    ...options.params,
    locale: getServiceLocalCode(),
  };

  return {
    url: formatterUrl,
    options: {
      ...options,
      headers: {
        ...options.headers,
        locale: getServiceLocalCode(),
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: fleetInfo.token,
        menuId,
      },
    },
  };
});

/**
 * 响应拦截器
 * code!=200 返回服务器信息
 */
request.interceptors.response.use(async (response) => {
  console.log(response.clone().json());
  const data = await response.clone().json();
  // 后端返回code非200情况统一进入错误处理
  if (Number(data.code) !== 200) {
    return Promise.reject(data);
  }
  return response;
});

export default request;
