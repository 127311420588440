import {
  getUserMenuList,
  getMessageList,
  authStationList,
} from "@/services/global";

const Global = {
  namespace: "global",
  state: {
    collapsed: false,
    microMethods: {},
    menuList: [],
    notice: 0,
    stationList: [],
    isMobile: false,
  },
  effects: {
    *microMethods({ payload }, { select }) {
      const microMethods = yield select((state) => state.global.microMethods);
      // 子应用自己暂时没有其他操作 后续可能会有
      // 调用主应用退出方法
      if (microMethods.mainAppLogout && payload?.type === "mainMicroLogout") {
        microMethods.mainAppLogout();
      }
    },

    /**
     * 获取用户菜单
     * @param payload
     * @param call
     * @param put
     * @returns {Generator<*, void, *>}
     */
    *userMenuList({ payload }, { call, put }) {
      const res = yield call(getUserMenuList, payload);
      if (res.code === "200") {
        yield put({
          type: "saveMenuList",
          payload: res.data.menuList,
        });
      }
    },

    /**
     * 获取未读消息数量
     * @param payload
     * @param call
     * @param put
     * @returns {Generator<*, void, *>}
     */
    *getNoticeCount({ payload }, { call, put }) {
      const res = yield call(getMessageList, payload);

      if (res.code === "200") {
        yield put({
          type: "setNoticeAccount",
          payload: res.data.length,
        });
      }
    },

    /**
     * 获取当前车队充电场站列表
     * @param payload
     * @param call
     * @param put
     * @returns {Generator<*, void, *>}
     */
    *getStationList({ payload }, { call, put }) {
      const res = yield call(authStationList, payload);

      if (res.code === "200") {
        yield put({
          type: "setStationList",
          payload: res.data.map((v) => ({
            label: v.stubGroupName,
            value: v.id,
          })),
        });
      }
    },
  },
  reducers: {
    // 保存微前端相关方法 主应用/子应用 目前只存了主应用
    saveMicroMethods(state, { payload }) {
      return {
        ...state,
        microMethods: payload,
      };
    },
    changeLayoutCollapsed(state, { payload }) {
      return { ...state, collapsed: payload };
    },
    saveMenuList(state, { payload }) {
      return {
        ...state,
        menuList: payload,
      };
    },
    setNoticeAccount(state, { payload }) {
      return {
        ...state,
        notice: payload,
      };
    },
    setStationList(state, { payload }) {
      return { ...state, stationList: payload };
    },

    setScreenType(state, { payload }) {
      return { ...state, isMobile: payload };
    },
  },
};
export default Global;
