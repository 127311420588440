import React from "react";

import { ConfigProvider } from "antd";
// 主题切换
import { changeThemeColor } from "@/utils/micro/theme";

import "./global.less";
// 缓存主应用传递状态和方法
let mAppProps = null;

// sentry接入开始>>>>
const sentryAlreadyCalled = () => {
  let initAlreadyCalled = false;
  const __sentry = window["__SENTRY__"];
  if (
    !(typeof __sentry === "undefined") &&
    __sentry.hub &&
    __sentry.hub.getClient()
  ) {
    initAlreadyCalled = true;
  }
  return initAlreadyCalled;
};
const getEnv = () => {
  // 或者自己实现环境读取方法
  const { NODE_ENV } = process.env;
  return (
    {
      production: "prod",
    }[NODE_ENV] || NODE_ENV
  );
};
const getVersion = () => {
  // 或者自己实现版本读取方法
  return process.env.APP_VERSION || "latest";
};
// const whichEnvNeedSentry = ["prod"];
// if (!sentryAlreadyCalled() && whichEnvNeedSentry.includes(getEnv())) {
//   Sentry.init({
//     dsn: "https://b67a131ca1054435ba6401c3991eae2e@sentry.starcharge.com/24",
//     environment: getEnv(),
//     release: getVersion(),
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
//     ignoreErrors: ["ResizeObserver loop limit exceeded"],
//     // debug: true,
//   });
// }
// sentry接入结束<<<<

// dva错误处理
export const dva = {
  config: {
    onError(e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    },
  },
};

// 初始化系统全局状态和方法
export async function getInitialState() {
  if (mAppProps) {
    return {
      mAppProps,
    };
  }
  return null;
}

/**
 * 包裹configProvider层改变class中的ant前缀
 * @param {*} container
 */
export function rootContainer(container) {
  // 主应用传递主题颜色/运行时主题切换
  if (mAppProps && mAppProps.themeColor) {
    changeThemeColor(mAppProps.themeColor);
  } else {
    // 应用自行配置主题
  }
  return React.createElement(
    ConfigProvider,
    {
      prefixCls: "scd",
    },
    container
  );
}

export const qiankun = {
  // 应用加载之前
  // async bootstrap(props) {
  // },
  // 应用 render 之前触发
  async mount(props) {
    // 缓存主应用props
    mAppProps = props;
  },
  // 应用卸载之后触发
  async unmount(props) {
    // eslint-disable-next-line no-console
    console.log("antd_sub unmount", props);
  },
};
