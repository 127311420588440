export default {
  validator: {
    bankNo: "请输入正确的银行卡号",
    idNo: "请输入正确的身份证号",
    phoneRequired: "请输入正确的手机号码",
    phoneDigit: "请输入11位有效手机号码！",
    idNoDigit: "请输入18位有效号码！",
    idNoDigitPlus: "请输入9位或18位有效号码！",
    vin: "VIN码必须为17位有效字符！",
    common: "仅支持输入数字字母和汉字！",
    eCard: "电卡位数不能大于10位",
    digit: "小数点精度2位！",
    password: "密码必须由大小写字母、数字、特殊字符组合并且大于12位",
    timeRequired: "请选择时间",
    email: "请输入正确的邮箱",
  },
  tip: {
    noLink: "暂无下载链接",
    batchAddSuccess: "批量新增成功",
  },
};
