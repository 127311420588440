export default {
  layout: {
    account: "账号",
    notification: "通知",
    logoff: "注销",
    information: "我的信息",
    changePass: "修改密码",
    current: "当前车队",
    add: "添加",
    default: "默认",
    delete: "删除",
  },
  newLogin: {
    title: "智能充电车队管理系统",
    footer: "",
    navTips: "The browser you are currently using is not compatible",
    adviceNav:
      "In order to provide you with better use experience, it is recommended to use Google Chrome",
    versionNumber: "版本号",
    passwordTips: "请输入密码",
    usernameTips: "请输入您的账号",
    VerificationCode: "验证码",
    tips: "验证码错误",
    codeTips: "请输入验证码",
    login: "登录",
    verificationCode: "验证码",
    account: "账号",

    password: "密码",
    telTips: "请输入您的手机号",
    immediateVerification: "立即校验",
    verificationTips: "Please enter the SMS verification code",
    sendLater: "秒后重新发送",
    GetAuthenticationCode: "获取验证码",
    modifyPassword: "修改密码",
    resetPasswordTip: "当前密码过于简单，为保证您的账号安全，请立即修改密码。",
    resetPassword: "重置密码",
    originalPassword: "当前密码",
    EnterOriginalPassword: "请输入原登录密码",
    confirmPassword: "确认密码",
    newPassword: "新密码",
    forgotPwd: "忘记密码",
    TypeNewPasswordAgain: "确认密码",
    EnterNewPassword: "Please enter a new password",
    clickEnter: "点击进入",
    second: "Remaining Time:",
    areaSelectTips: "根据合约所在地，选择数据大区",
    PasswordCombination: "至少12位大小写字母数字加特殊字符组合",
    tips1: "修改密码成功，请重新登录",
    tips2: "两次密码输入不一致",
    tips3: "请输入确认密码",
  },
  menu: {
    dashboard: "首页",
    首页: "首页",
    订单中心: "订单中心",
    充电订单: "充电订单",
    占位订单: "占位订单",
    车队管理: "车队管理",
    车辆信息: "车辆信息",
    电卡管理: "电卡管理",
    Vin码鉴权: "Vin码鉴权",
    充电电站: "充电电站",
    员工充电报表: "员工充电报表",
    车辆充电报表: "车辆充电报表",
    财务管理: "财务管理",
    司机账户: "司机账户",
    新增账户: "新增账户",
    修改账户: "修改账户",
    变动记录: "变动记录",
    发票申请: "发票申请",
    开票历史: "开票历史",
    充电账单: "充电账单",
    账单明细: "账单明细",
    生成账单: "生成账单",
    基础信息: "基础信息",
    我的信息: "我的信息",
    基础设置: "基础设置",
    管理员: "管理员",
    修改密码: "修改密码",
  },
  page: {
    filter: {
      beginTime: "开始时间",
      endTime: "结束时间",

      emptyNotice: "请输入",
      dateAdded: "添加日期",
      administratorAccount: "管理员账号",
      administratorName: "管理员姓名",
      accountStatus: "账号状态",
      selectEmptyNotice: "请选择",
      select: "选择",
      reset: "重置",
      inquire: "查询",
      addManager: "新增管理员",
      enterAdministratorName: "请输入管理员姓名",
      maxEnter: "最多输入30个字符",
      enterAdministratorAccount: "请输入管理员账号",
      Pleaseenterkeywords: "请输入关键词",
      chargingEndTime: "日期范围",
      todayButton: "今",
      yesterdayButton: "昨",
      recent7Days: "近7日",
      recent30Days: "近30日",
      stationName: "电站名称",
      stationNamePlaceholder: "请选择充电场站",
      chargingGunNumber: "枪编号",
      chargingGunNumberPlaceholder: "请精确输入枪编号",
      userAccount: "用户账号",
      userAccountPlaceholder: "请输入11位手机号",
      PleaseEnterEmailAddress: "请输入手机号",
      orderNumber: "订单号",
      orderNumberPlaceholder: "请精确输入订单号",
      numberplate: "车辆信息",
      numberplatePlaceholder: "请精确输入",
      activationMethod: "启动方式",
      activationMethodPlaceholder: "请选择启动方式",
      chargingStatus: "充电状态",
      chargingStatusPlaceholder: "请选择充电状态",
      exportReport: "导出报表",
      PleaseUploadTheFile: "请上传文件",
      ModifiedSuccessfully: "修改成功",
      OrdPassword: "原密码",
      PleaseEnterOriginalPassword: "请输入原密码",
      NewPassword: "新密码",
      PleaseEnterNewPassword: "请输入新密码",
      ConfirmPassword: "确认密码",
      PleaseEnterTheNewPasswordAgain: "请再次输入新密码",
      FundData: "资金数据",
      GetSMSode: "获取验证码",
      SentSuccessfully: "发送成功",
      OperationSuccessfully: "操作成功",
      AccountAllocation: "账户分配",
      CurrentAccount: "当前账号",
      DistributionAmount: "分配金额(元)",
      AccountRecovery: "账户回收",
      EnterAmount: "输入金额(元)",
      FullRecovery: "全部回收",
      CustomAmount: "自定义金额",
      RecoveryAmount: "请输入回收金额",
      BatchAdditionSucceeded: "批量新增成功",
      tips1: "单次查询的最大区间是31天",
      tips2: "数据统计时间为截至当前时间。",
      tips3: "禁用后将关闭该账号充电功能，确认禁用？",
      tips4: "确认启用？",
      tips5: "当前司机正在充电，是否停止充电？",
      tips6: "订单号和充电结束时间必填一项",
      Allocatable: "最大可分配",
      PleaseEnterANumberGreater: "请输入大于0的数",
      EnterUpTo10Digits: "最多输入30位",
      AmountRecoveredSuccessfully: "分配金额成功",
      PleaseSelectStation: "请选择充电场站",
      Recyclable: "最多可回收(元)",
      Second: "秒",
      PleaseEnterEmail: "请输入手机号",
      PleaseEnterUserName: "请输入用户名",
    },
    dashboard: {
      displayName: "首页",
      dataOverview: "数据概览",
      chargingCapacityToday: "今日充电数据",
      chargingOrder: "充电订单",
      statisticsAreAsOfTheCurrentUpdateTime: "统计数据为截止当前更新时间",
      totalPowerChargedToday: "今日充电（度）",
      chargingFee: "充电电费（元）",
      serviceFee: "充电服务费（元）",
      chargingAmount: "充电金额（元）",
      numberOfCharging: "充电次数（次）",
      chargingCapacityTodayChart: "今日充电量",
      chargingCapacityYesterdayChart: "昨日充电量",
      cashData: "资金数据",
      fleetAccountBalance: "车队账户余额（元）",
      accountBalance: "司机账户余额（元）",
      userData: "用户数据",
      chargingManagement: "充电管理",
      numberOfChargingUsers: "充电司机数",
      numberOfChargingVehicles: "充电车辆数",
    },
    administratorTable: {
      administratorAccount: "管理员账号",
      administratorName: "管理员姓名",
      accountStatus: "账号状态",
      role: "角色",
      updateTime: "修改时间",
      confirmResetPassword: "确认重置密码？",
      resetPassword: "重置密码",
      yes: "是",
      no: "否",
      confirm: "确认",
      unlock: "解锁",
      confirmUnlock: "确认解锁？",
      delete: "删除",
      confirmDelete: "确认操作？",
      enable: "启用",
      disable: "禁用",
      superAdmin: "超级管理员",
      normalAdmin: "普通管理员",
      normal: "正常",
      add: "添加",
      default: "默认",
      Cancel: "取消",
    },
    carInfo: {
      stationSelfCoding: "自编码",
      addCar: "新增车辆",
      notice:
        "特别说明：部分车辆或充电桩不支持获取VIN码，实际充电订单可能获取不到车辆信息。",
    },
    account: {
      account: "账号",
      userName: "用户名",
      accountType: "账户类型",
      accountStatus: "账户状态",
      addAccount: "添加账户",
      balance: "账户余额",
      operator: "操作人",
      operateTime: "操作时间",
      chargingSetting: "充电设置",
      more: "更多",
      edit: "编辑",
      delete: "删除",
      recycle: "回收",
      recycleTip: "当前没有可回收的余额",
      distribute: "分配",
      disable: "禁用",
      UserName: "用户名",
      enable: "启用",
      Stopping: "正在停止",
      normal: "正常",
      PleaseCompleteTheInformation: "请补全信息",
    },
    invoiceApply: {
      chooseCompany: "选择企业",
      apply: "提交申请",
      invoiceDetail: "开票内容",
      paymentTime: "支付时间",
      stationName: "电站名称",
      invoiceHistory: "开票历史",
      invoiceCompany: "开票企业",
      invoiceAmount: "可开票金额(元)",
    },
    chargingBill: {
      billDate: "账单生成日",
      addBill: "添加账单",
      billPeriod: "账单周期",
      name: "运营商名称",
      electricityPrice: "电费",
      billTime: "账单生成时间",
      operator: "操作人",
      serviceFee: "服务费",
      chargingVolume: "充电量",
      totalAmount: "总金额",
      orderDetail: "账单明细",
      unit: "度",
      // bill,
    },
    changeLog: {
      changeDate: "变动日期",
      typeOfChange: "变动类型",
      subjectOfChange: "变动主体",
      summary: "周期内金额汇总（元）",
      serialNumber: "交易流水号",
      operator: "操作人账号",
      operateTime: "变动时间",
      changeAmount: "变动金额(元)",
      currentBalance: "当前余额(元)",
      affiliatedCompany: "所属企业",
      UberCar: "网约车",
      Bus: "公交车",
      RentalCar: "分时租赁",
      LogisticsExpress: "物流快递",
      Passengerbus: "客运大巴",
      Carrental: "汽车租赁",
      MainEngineFactory: "主机厂",
      SmallBusiness: "小公司企业",
      GovernmentRelated: "政府相关",
      WanBangInternal: "万帮内部",
    },
    occupation: {
      timeUnit: "分钟",
      priceUnit: "元",
      stationName: "电站名称",
      userAccount: "用户账号",
      searchOrder: "订单搜索",
      startTime: "占位开始时间",
      chargingDevice: "充电应用",
      gunNumber: "枪编号",
      orderStatus: "订单状态",
      complaintStatus: "申诉状态",
      chargingInfo: "充电信息",
      occupationInfo: "占位信息",
      paymentStatus: "付款信息",
      status: "状态",
      operation: "操作",
      enterpriseName: "企业名称",
      transactionID: "订单号",
      billingPrice: "计费价格",
      maximumAmount: "上限金额",
      paymentTime: "支付时间",
      chargingAPP: "充电应用",
      occupationTimeDuration: "占位时长",
      payableAmount: "应付金额",
      chargingTimeDuration: "充电时间",
      freeDuration: "免费时长",
      amountPaid: "实付金额",
      occupationTime: "占位时间",
      billingTimeDuration: "计费时长",
      viewClaims: "查看申述",
      chargingTransaction: "充电订单",
      billingAmount: "计费金额",
      complain: "发起申诉",
      Discount: "服务费折扣",
      FixedServiceFee: "固定服务费",
      FixedTotalPrice: "固定总价",
      ServiceChargeFixed: "服务费按电费固定比例",
      ServiceFeeDiscount: "服务费折扣",
      ElectricityDiscount: "电费折扣",
      TimePeriodDiscount: "分时段折扣",
      FixedDiscountForElectricityAndServiceCharges: "电费、服务费固定值折扣",
      unit: "元/度",
      peak: "峰",
      tip: "尖",
      usually: "谷",
      vec: "平",
    },
    chargingTransaction: {
      stationName: "电站名称",
      endTimeOfCharging: "充电结束时间",
      today: "今",
      yesterday: "昨",
      nearly7Days: "近7日",
      nearly30Days: "近30日",
      paymentType: "支付类型",
      paymentStatus: "支付状态",
      all: "全部",
      personalPayment: "个人支付",
      personal: "个人",
      Enterprise: "企业",
      fleetPayment: "EV车队支付",
      partnerPayment: "互联互通支付",
      creditCardPayment: "卡平台支付",
      paid: "已支付",
      unpaid: "未支付",
      userAccount: "用户账号",
      enterpriseName: "企业名称",
      vehicleInformation: "车辆信息",
      SelectVehicle: "选择车辆",
      VINCode: "VIN码",
      plateNumber: "车牌号",
      connectorID: "枪编号",
      startReason: "启动方式",
      chargingStatus: "充电状态",
      transactionNumber: "订单号",
      transactionStatus: "订单状态",
      stopReason: "停止原因",
      highRiskModels: "高危车型",
      // stationName: "电站名称",
      // transactionNumber: "订单号",
      // connectorID: "枪编号",
      startChargingTime: "充电开始时间",
      initialSOC: "初始SOC",
      transactionChargingVolume: "订单电量(度)",
      startMethod: "启动方式",
      endChargingTime: "充电结束时间",
      endSOC: "结束SOC",
      originalElectricityPrice: "电费原价(元)",
      userName: "用户姓名",
      originalServicePrice: "服务费原价(元)",
      totalFeeOfTheTransaction: "订单总金额(元)",
      transactionDetails: "订单详情",
      chargingProcess: "充电过程",
      AppletStartup: "小程序启动",
      PowerCardStart: "电卡启动",
      OnlineVin: "在线VIN",
      AppStartup: "App启动",
      charging: "充电中",
      ChargingCompleted: "充电完成",
      SharedAccount: "共享账户",
      IndependentAccount: "独立账户",
      submit: "提交",
      UnbindingSucceeded: "解绑成功",
      UnbindVehicle: "解绑车辆",
      AreUouSureYouWantToUnbind: "确定要解绑吗？",
      ChargingStation: "充电充电场站",
      UnlimitedStation: "不限充电场站",
      DesignatedStation: "指定充电场站",
      ChargingPeriod: "充电时段",
    },
    baseInfo: {
      basicInformation: "基础信息",
      invoiceInformation: "开票信息",
      fleetName: "车队名称",
      ownerName: "法人姓名",
      ownerMobile: "法人手机号",
      identificationNumber: "证件号码",
      useOfVehicle: "车辆类型",
      permissionOfStation: "充电场站权限",
      detail: "点击查看",
      invoiceType: "开票类型",
      invoiceTitle: "发票抬头",
      accountInformation: "账户信息",
      statisticsAreAsOfTheCurrentUpdateTime: "统计数据为截止当前更新时间",
      balance: "账户余额（元）",
      changeLog: "变动记录",
      account: "账号信息",
      adminAccount: "后台管理员账号",
      switch: "更换账号",
      adminName: "管理员姓名",
      status: "账号状态",
      fleetMember: "充电成员",
      numberOfDrivers: "已有充电司机数",
      numberOfVehicles: "已有充电车辆数",
      ChangeAdministratorAccount: "更换管理员账号",
      CurrentMobileNumber: "当前手机号",
      CurrentAccountVerificationCode: "当前账号验证码",
      NewMobileNumber: "新手机号",
      phoneTips1: "手机号已更换为",
      phoneTips2: "秒后重新获取",
      phoneTips3: "输入新手机号收到的短信验证码",
      PleaseLoginAgain: "请重新登录",
      LoginAgain: "重新登录",
      phoneTips4: "请输入新的手机号",
      phoneTips5: "输入当前手机号收到",
      NewMobileNumberverificationCode: "新手机号验证码",
    },
    VehicleInformation: {
      SelfEncoding: "自编号",
      // DriverAccount: "所属司机账户",
      TimeModified: "变动时间",
      ModifyInformation: "修改信息",
      delete: "删除",
      NumberPlate: "车牌号",
      VinCode: "VIN码",
      SpecialInstructions:
        "特别说明：部分车辆或充电桩不支持获取VIN码，实际充电订单可能获取不到车辆信息。",
      singleNew: "单个新增",
      batchNew: "批量新增",
      newWay: "新增方式",
      AddOrEditVehicles: "新增/编辑车辆",
      PleaseEnterLicensePlateNumber: "请输入车牌号",
      PleaseEnterVINCode: "请输入VIN码",
      UploadFailList: "上传失败列表",
      DriverInformation: "司机信息",
      DriverAccount: "司机账号",
      PleaseEnterDriverAccount: "请输入司机账号",
      PleaseEnterOneUserName: "请输入用户名",
      tips1: "1、下载模板，将车辆信息填写到模板中",
      tips2: "仅支持选择已录入车辆。",
      tips3: "当前司机有正在进行的订单，保存的信息将在订单完成后生效",
      tips4:
        "切换成共享账户后，独立账户下的资金不会主动回收，请注意及时回收资金",
      tips5: "当前电卡已有司机账户，无法再次添加。",
      tips6: "当前车辆已有司机账户，无法再次添加。",
      tips7: "起始时间不能大于结束时间",
      tips8: "时间段不能重复，请检查您的时间段",
      tips9: "时间区间不能有交叉重合",
      tips10: "如司机当前正在充电，保存设置将在订单完成后自动生效",
      tips11: "请填写新的管理员账号",
    },
    chargingStation: {
      StationName: "电站名称",
      AffiliatedCompany: "所属企业",
      AccountManagerName: "客户经理",
      AccountManagerMobileNumber: "客户经理手机号",
      StationAddress: "充电场站地址",
      DCcharger: "直流设备",
      ExclusiveDiscount: "专属折扣",
      DiscountEffectiveTime: "生效时间",
      serviceHours: "服务时间",
      ACcharger: "交流设备",
      DiscountTypeValue: "折扣值",
      TransactionNotification: "事务通知",
      Unread: "未读",
      NoMessageContent: "暂无消息内容",
      hoursAgo: "小时前",
      minutesAgo: "分钟前",
      secondsAgo: "秒前",
      NoDownloadLink: "暂无下载链接",
      PleaseCloseExtraWindows: "请关闭多余窗口!",
      MarkRead: "标记已读",
    },
    ElectricityCard: {
      cardNumber: "卡号",
      BindingSubject: "绑定主体",
      BindingState: "绑定状态",
      TheElectricCardStatus: "电卡状态",
      AddTheElectricCard: "添加电卡",
      BatchAdd: "批量添加",
      disable: "禁用",
      delete: "删除",
      TimeModified: "变动时间",
      enable: "启用",
      UploadFailureList: "上传失败列表",
      ClickDownload: "点击下载",
      downloadStep1: "下载模板，将电卡信息填写到模板中；",
      downloadStep2: "将填写好的模板上传。",
      UploadFile: "上传文件",
      DownloadTemplate: "下载模板",
      ElectricCardInfo: "电卡信息",
      SelectElectricCard: "选择电卡",
      UnbindElectricCard: "解绑电卡",
      ElectricCardNumber: "电卡卡号",
    },
    orderDetail: {
      orderDetail: "订单详情",
      carselfcoding: "车辆自编码",
      LengthTime: "充电时长",
      InitialSOC: "初始SOC",
      EndSOC: "结束SOC",
      Timeofpayment: "支付时间",
      MethodOfPayment: "支付方式",
      Amountplatform: "应付金额(元)",
      Discountamount: "折扣金额(元)",
      Discounttype: "折扣类型",
      actuallyPaid: "实付金额(元)",
      ExporSrder: "导出订单",
      OrderTime: "订单时间",
      CollatingTableDateExport: "正在整理要导出的表格数据，请稍后...",
      YouCanLeaveThisPage:
        "您可以离开此页面，导出将在后台进行，完成后请到“通知”进行下载。",
      Ok: "知道了",
    },
    viewcomplaint: {
      viewcomplaint: "查看申诉",
      Complaintdescription: "申诉说明",
      Complainpicture: "申诉图片",
    },
    billDetail: {
      exportStatement: "导出报表",
      orderNO: "订单编号",
      powerStationName: "电站名称",
      userAccount: "用户账号",
      chargingAmount: "充电量",
      OriginalElectricityPrice: "电费原价",
      Connectornumber: "枪编号",
      licenseNumber: "车牌号",
      actuallyPaid: "实付金额",
      chargingTime: "充电时间",
    },
    VINPermissions: {
      whetherEnable: "是否开启Vin码鉴权",
      No: "不开启",
      Yes: "开启",
      Instructions: "说明",
      tips:
        "vin码鉴权开关开启时，员工使用企业账户充电时，会校验当前车辆是否绑定，如果没有绑定无法启动，请使用个人账户启动。",
      msg:
        "vin码鉴权开关关闭时，员工使用企业账户充电，不进行车辆校验，有余额则可以正常启动。",
      Save: "保存",
    },
    Employee: {
      Username: "用户名",
      MobilePhoneNumber: "手机号",
      ReportType: "报表类型",
      Date: "日期",
      NumberOfCharging: "充电次数",
      ChargingDuration: "充电时长",
      Search: "查询",
      Export: "导出",
      Totalchargingamount: "总充电量（度）",
      Chargingcost: "充电费用（元）",
      Electricityfee: "充电电费（元）",
      Servicefee: "充电服务费（元）",
      ElectricQuantityatshoulderhour: "充电平时电量（度）",
      ElectricityFeeatshoulderhour: "充电平时电费（元）",
      ServiceFeeatshoulderhour: "充电平时服务费（元）",
      ElectricQuantityatsharphour: "充电尖时电量（度）",
      ElectricityFeeatsharphour: "	充电尖时电费（元）",
      ServiceFeeatsharphour: "充电尖时服务费（元）",
      ElectricQuantityatpeakhour: "充电峰时电量（度）",
      ElectricityFeeatpeakhour: "充电峰时电费（元）",
      ServiceFeeatpeakhour: "充电峰时服务费（元）",
      ElectricQuantityatoffpeakhour: "充电谷时电量（度）",
      ElectricityFeehour: "充电谷时电费（元）",
      ServiceFeehour: "充电谷时服务费（元）",
      LicenseplateNo: "车牌号",
      Vehiclecode: "车辆编码",
      date: "日报",
      month: "月报",
      year: "年报",
      other: "旬报",
      other1: "上旬",
      other2: "中旬",
      other3: "下旬",
      self: "自定义时间",
      monthSelect: "月份选择",
      yearSelect: "年份选择",
      otherSelect: "月旬选择",
      selfSelect: "范围选择",
    },
  },
};
