import request from "@/utils/request";

/**
 * 获取车辆列表
 * @param params
 * @returns {Promise<any>}
 */
export async function getCarList(params) {
  return request.get("/mtsV2/getCarList", { params });
}

/**
 * 新增车辆
 * @param params
 * @returns {Promise<any>}
 */
export async function addCar(params) {
  return request.post("/mtsV2/addCar", { params });
}

/**
 * 编辑或删除车辆
 * @param params
 * @returns {Promise<any>}
 */
export async function updateCar(params) {
  return request.post("/mtsV2/editCar", { params });
}
