import ThemeColorReplacer from "webpack-theme-color-replacer";
import client from "webpack-theme-color-replacer/client";
import { generate } from "@ant-design/colors/dist";

/**
 * 取得更换颜色序列
 * @param {*} color 主色
 */
export const getAntdSerials = (color) => {
  const lightNum = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const devide10 = 10;
  // 淡化（即less的tint）
  const lightens = lightNum.map((_, i) => {
    return ThemeColorReplacer.varyColor.lighten(color, i / devide10);
  });
  const colorPalettes = generate(color);
  const rgb = ThemeColorReplacer.varyColor.toNum3(color.replace("#", "")).join(",");
  return lightens.concat(colorPalettes).concat(rgb);
};

/**
 * 嵌入生成主题色
 * @param {*} color 主色
 */
export const changeThemeColor = (color = "#F08300") => {
  return client.changer.changeColor({ newColors: [...getAntdSerials(color)] }, Promise);
};
