import request from "@/utils/request";

/**
 * 获取变动类型
 * @param params
 * @returns {Promise<any>}
 */
export async function queryChangeEnum(params) {
  return request.get("/mtsV2/ignore/getMoneyChangeEnum", { params });
}

/**
 * 获取周期内金额汇总
 * @param params
 * @returns {Promise<any>}
 */
export async function queryChangeTotal(params) {
  return request.get("/mtsV2/ignore/FleetMoneyChangeTotal", { params });
}

/**
 * 获取变动记录列表
 * @param params
 * @returns {Promise<any>}
 */
export async function queryChangeList(params) {
  return request.get("/mtsV2/ignore/FleetMoneyChange", { params });
}
