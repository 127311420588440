// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'D:/project/xian/xi_fleet/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'D:/project/xian/xi_fleet/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'D:/project/xian/xi_fleet/src/layouts/UserLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "login",
            "path": "/user/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'D:/project/xian/xi_fleet/src/pages/user/Login'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'D:/project/xian/xi_fleet/src/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'D:/project/xian/xi_fleet/src/layouts/BasicLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/",
                "redirect": "/home",
                "exact": true
              },
              {
                "name": "首页",
                "path": "/home",
                "key": "0001",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'D:/project/xian/xi_fleet/src/pages/home'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "订单中心",
                "path": "/order",
                "key": "0002",
                "icon": "icondingdanguanli-weixuanzhong",
                "tabIndex": 1,
                "routes": [
                  {
                    "name": "充电订单",
                    "path": "/order/charge",
                    "key": "000201",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__order__charge' */'D:/project/xian/xi_fleet/src/pages/order/charge'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "占位订单",
                    "path": "/order/seize",
                    "key": "000202",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__order__seize' */'D:/project/xian/xi_fleet/src/pages/order/seize'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "name": "车队管理",
                "path": "/fleet",
                "key": "0003",
                "icon": "iconcheduiguanli-weixuanzhong",
                "routes": [
                  {
                    "name": "车辆信息",
                    "path": "/fleet/carInfo",
                    "key": "000302",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fleet__carInfo' */'D:/project/xian/xi_fleet/src/pages/fleet/carInfo'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "电卡管理",
                    "path": "/fleet/electricCard",
                    "key": "000306",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fleet__electricCard' */'D:/project/xian/xi_fleet/src/pages/fleet/electricCard'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "Vin码鉴权",
                    "path": "/fleet/vinCode",
                    "key": "000307",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fleet__vinCode' */'D:/project/xian/xi_fleet/src/pages/fleet/vinCode'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "充电电站",
                    "path": "/fleet/chargeStation",
                    "key": "000303",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fleet__chargeStation' */'D:/project/xian/xi_fleet/src/pages/fleet/chargeStation'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "员工充电报表",
                    "path": "/fleet/employeeReport",
                    "key": "000308",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fleet__employeeReport' */'D:/project/xian/xi_fleet/src/pages/fleet/employeeReport'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "车辆充电报表",
                    "path": "/fleet/carChargeReport",
                    "key": "000309",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fleet__carChargeReport' */'D:/project/xian/xi_fleet/src/pages/fleet/carChargeReport'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "name": "财务管理",
                "path": "/financial",
                "key": "0004",
                "icon": "iconcaiwuguanli-weixuanzhong",
                "routes": [
                  {
                    "name": "司机账户",
                    "path": "/financial/account",
                    "key": "000402",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__driverAccount' */'D:/project/xian/xi_fleet/src/pages/financial/driverAccount'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "新增账户",
                    "path": "/financial/account/create",
                    "key": "000402",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__driverAccount__Component__CreateAccount' */'D:/project/xian/xi_fleet/src/pages/financial/driverAccount/Component/CreateAccount'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "name": "修改账户",
                    "path": "/financial/account/edit",
                    "key": "000402",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__driverAccount__Component__CreateAccount' */'D:/project/xian/xi_fleet/src/pages/financial/driverAccount/Component/CreateAccount'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "name": "变动记录",
                    "path": "/financial/change",
                    "key": "000403",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__changeRecord' */'D:/project/xian/xi_fleet/src/pages/financial/changeRecord'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "发票申请",
                    "path": "/financial/invoiceApply",
                    "key": "000404",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__invoiceApply' */'D:/project/xian/xi_fleet/src/pages/financial/invoiceApply'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "开票历史",
                    "hideInMenu": true,
                    "key": "000404",
                    "path": "/financial/invoiceApply/history",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__invoiceApply__history' */'D:/project/xian/xi_fleet/src/pages/financial/invoiceApply/history'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "充电账单",
                    "path": "/financial/chargingBill",
                    "key": "000405",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__chargingBill' */'D:/project/xian/xi_fleet/src/pages/financial/chargingBill'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "账单明细",
                    "path": "/financial/chargingBill/billDetail",
                    "key": "000405",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__chargingBill__Component__billDetail' */'D:/project/xian/xi_fleet/src/pages/financial/chargingBill/Component/billDetail'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "name": "生成账单",
                    "path": "/financial/chargingBill/createBill",
                    "key": "000405",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__chargingBill__Component__createBill' */'D:/project/xian/xi_fleet/src/pages/financial/chargingBill/Component/createBill'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  }
                ]
              },
              {
                "name": "基础信息",
                "path": "/basicInfo",
                "key": "0005",
                "icon": "iconjichuxinxi-weixuanzhong",
                "routes": [
                  {
                    "name": "我的信息",
                    "path": "/basicInfo/myInfo",
                    "key": "000501",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basicInfo__myInfo' */'D:/project/xian/xi_fleet/src/pages/basicInfo/myInfo'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "name": "基础设置",
                "path": "/setting",
                "key": "0006",
                "icon": "iconshezhi",
                "routes": [
                  {
                    "name": "管理员",
                    "key": "000601",
                    "path": "/setting/administrator",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basicSetting__administrator' */'D:/project/xian/xi_fleet/src/pages/basicSetting/administrator'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "修改密码",
                    "path": "/setting/modifyPwd",
                    "key": "000602",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basicSetting__modifyPassword' */'D:/project/xian/xi_fleet/src/pages/basicSetting/modifyPassword'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
