import request from "@/utils/request";

/**
 * 获取用户菜单
 * @param params
 * @returns {Promise<any>}
 */
export async function getUserMenuList(params) {
  return request.get("/mtsV2/user/menu", { params });
}

/**
 * 获取消息列表
 * @param params
 * @returns {Promise<any>}
 */
export async function getMessageList(params) {
  return request.get("/mtsV2/admin/message/find", { params });
}

/**
 * 获取当前车队下充电充电场站
 * @param params
 * @returns {Promise<any>}
 */
export async function authStationList(params) {
  return request.get("/mtsV2/ignore/findStationInfo/byFleetId", { params });
}
/**
 * 更新消息状态
 * @param params
 * @returns {Promise<any>}
 */
export async function updateMessageType(params) {
  return request.get("/mtsV2/admin/message/read", { params });
}

/**
 * 获取订单下载url
 * @param params
 * @returns {Promise<any>}
 */
export async function getChargeOrderUrl(params) {
  return request.get("/mtsV2/admin/message/url/get", { params });
}

/**
 * 获取模板文件
 * @param params
 * @returns {Promise<any>}
 */
export async function queryDataMap(params) {
  return request.get("/mtsV2/ignore/commParam/get", { params });
}
