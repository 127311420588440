import request from "@/utils/request";

/**
 * 账单
 * @param params
 * @returns {Promise<any>}
 */
export async function evcsSettlementList(params) {
  return request.post("/mtsV2/evcs/settlementList", { params });
}
/**
 * 详细账单
 * @param params
 * @returns {Promise<any>}
 */
export async function evcsSettlementOrder(params) {
  return request.post("/mtsV2/evcs/settlement/order", { params });
}
/**
 * 对账单
 * @param params
 * @returns {Promise<any>}
 */
export async function settlementEvcsBillDaily(params) {
  return request.post("/mtsV2/evcs/settlement/evcs/bill/daily", { params });
}
/**
 * 按选择日期范围汇总待结算的汇总数据
 * @param params
 * @returns {Promise<any>}
 */
export async function evcsDailySummary(params) {
  return request.post("/mtsV2/evcs/dailySummary", { params });
}
/**
 * 生成账单
 * @param params
 * @returns {Promise<any>}
 */
export async function evcsApplySettlement(params) {
  return request.post("/mtsV2/evcs/applySettlement", { params });
}
