import request from "@/utils/request";

/**
 *获取车队信息
 * @param params
 * @returns {Promise<any>}
 */
export async function queryFleetInfo(params) {
  return request.get("/mtsV2/ignore/fleetInfo/get", { params });
}

/**
 * 获取车队开票信息
 * @param params
 * @returns {Promise<any>}
 */
export async function queryInvoiceInfo(params) {
  return request.get("/mtsV2/ignore/fleetInvoiceInfo/get", { params });
}

/**
 * 修改管理员账号短信
 * @param params
 * @returns {Promise<any>}
 */
export async function updateAdminAccountSms(params) {
  return request.post("/mtsV2/ignore/fleetInfo/UpdateAccount/sms", { params });
}

/**
 * 修改管理员账号
 * @param params
 * @returns {Promise<any>}
 */
export async function updateAdminAccount(params) {
  return request.post("/mtsV2/ignore/fleetInfo/updateAccount", { params });
}

/**
 * 获取用户信息
 * @param params
 * @returns {Promise<any>}
 */
export async function queryUserInfo(params) {
  return request.get("/mtsV2/ignore/getUser/info", { params });
}

/**
 * 获取车队人车卡数量
 * @param params
 * @returns {Promise<any>}
 */
export async function queryFleetCountInfo(params) {
  return request.get("/mtsV2/fleetCountInfo/get", { params });
}
