import {
  queryInvoiceInfo,
  queryFleetInfo,
  queryUserInfo,
  queryFleetCountInfo,
  updateAdminAccount,
} from "@/pages/basicInfo/myInfo/service";
import { getHomeDataSource } from "@/pages/home/service";
import contextApi from "@/utils/contextApi";
import { getIntl } from "@/.umi/plugin-locale/localeExports";
/**
 * 我的信息model
 * @type {{effects: {}, namespace: string, reducers: {setState(*, {payload: *}): *}, state: {}}}
 */
const Model = {
  namespace: "myInfoModel",
  state: {
    fleetData: {},
    invoiceData: {},
    countData: {},
    userData: {},
    homeData: {},
    visible: false,
  },
  effects: {
    /**
     * 获取页面展示信息
     * @param payload
     * @param call
     * @param put
     * @returns {Generator<Promise<*[]>, void, *>}
     */
    *search({ payload }, { put }) {
      const responseList = yield Promise.all([
        queryFleetInfo({ fleetId: payload.fleetId }),
        queryInvoiceInfo({ fleetId: payload.fleetId }),
        queryUserInfo(payload),
        queryFleetCountInfo(),
        getHomeDataSource({ fleetId: payload.fleetId }),
      ]);
      const [
        fleetResponse,
        invoiceResponse,
        userResponse,
        countResponse,
        homeResponse,
      ] = responseList;

      yield put({
        type: "setState",
        payload: {
          fleetData: fleetResponse.data,
          invoiceData:
            invoiceResponse.data && invoiceResponse.data.length > 0
              ? invoiceResponse.data[0]
              : {},
          userData: userResponse.data,
          countData: countResponse.data,
          homeData: homeResponse.data,
        },
      });
    },
    /**
     * 更换账号
     * @param payload
     * @param call
     * @param put
     * @returns {Generator<*, void, *>}
     */
    *updateAccount({ payload, refresh }, { call, put }) {
      const res = yield call(updateAdminAccount, payload);
      const intl = getIntl();
      if (res && res.code === "200") {
        contextApi("message").success(
          intl.messages.page.filter.OperationSuccessfully
        );
        yield put({
          type: "setState",
          payload: {
            visible: false,
          },
        });
        refresh();
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
export default Model;
