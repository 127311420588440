const passwordConst = "Modify Password";
const confirmPasswordConst = "Confirm Password";
const stationNameConst = "Station Name";
const chargingGunNumberConst = "Connector ID";
const userAccountConst = "User Account";
const orderNumberConst = "Transaction Number";
export default {
  layout: {
    account: "Account Number",
    notification: "Notification",
    logoff: "Log Off",
    information: "My Information",
    changePass: passwordConst,
    current: "Fleet",
    add: "Add",
    default: "Default",
    delete: "Delete",
  },
  newLogin: {
    title: "Fleet Management System",
    footer: "",
    navTips: "The browser you are currently using is not compatible",
    adviceNav:
      "In order to provide you with better use experience, it is recommended to use Google Chrome",
    versionNumber: "Version",
    passwordTips: "Please enter the password",
    usernameTips: "Please enter your account",
    VerificationCode: "Verification Code",
    tips: "Verification code error",
    codeTips: "Please enter verification code",
    login: "Login",
    verificationCode: "Verification Code",
    account: "Account Number",
    password: "Password",
    telTips: "请输入您的手机号",
    immediateVerification: "立即校验",
    verificationTips: "Please enter the SMS verification code",
    sendLater: "秒后重新发送",
    GetAuthenticationCode: "get verification code",
    modifyPassword: passwordConst,
    resetPasswordTip: "当前密码过于简单，为保证您的账号安全，请立即修改密码。",
    resetPassword: "reset Password",
    originalPassword: "Current Password",
    EnterOriginalPassword: "请输入原登录密码",
    confirmPassword: confirmPasswordConst,
    newPassword: "New Password",
    forgotPwd: "Forgot Password",
    TypeNewPasswordAgain: confirmPasswordConst,
    EnterNewPassword: "Please enter a new password",
    clickEnter: "Click Enter",
    second: "Remaining Time:",
    areaSelectTips: "根据合约所在地，选择数据大区",
    PasswordCombination:
      "At least 12 uppercase and lowercase alphanumeric plus special characters",
    tips1: "Password modification succeeded. Please login again",
    tips2: "The two passwords are inconsistent",
    tips3: "Please enter the confirmation password",
  },
  menu: {
    dashboard: "Dashboard",
    首页: "Dashboard",
    订单中心: "Transaction Center",
    充电订单: "Transaction History",
    占位订单: "Occupation Transaction",
    车队管理: "Fleet management",
    车辆信息: "Vehicle information",
    电卡管理: "Electricity Card Management",
    Vin码鉴权: "VIN Authentication",
    充电电站: "Station",
    员工充电报表: "Employee Charging Report",
    车辆充电报表: "Vehicle Charging Report",
    财务管理: "Finance Management",
    司机账户: "Fleet Member",
    新增账户: "Add Fleet Member",
    修改账户: "Modify Account",
    变动记录: "Fleet Balance Log",
    发票申请: "Invoice Application",
    开票历史: "Billing history",
    充电账单: "Charging Bills",
    账单明细: "Billing Details",
    生成账单: "Generate bill",
    基础信息: "Basic information",
    我的信息: "My Information",
    基础设置: "Basic Setting",
    管理员: "Administrator",
    修改密码: "Modify Password",
  },
  page: {
    dashboard: {
      displayName: "Dashboard",
      dataOverview: "Data Overview",
      chargingOrder: "Charging Order",
      chargingCapacityToday: "Today's charging capacity",
      statisticsAreAsOfTheCurrentUpdateTime:
        "Statistics are as of the current update time",
      totalPowerChargedToday: "Total power charged today (kWh)",
      chargingFee: "Charging fee (S$)",
      serviceFee: "Service fee(S$)",
      chargingAmount: "Charging amount (S$)",
      numberOfCharging: "Number of charging",
      chargingCapacityTodayChart: "Charging capacity today",
      chargingCapacityYesterdayChart: "Charging capacity yesterday",
      cashData: "Cash Data",
      fleetAccountBalance: "Fleet account balance(S$)",
      accountBalance: "Account Balance(S$)",
      userData: "User data",
      chargingManagement: "Charging Management",
      numberOfChargingUsers: "Number of charging users",
      numberOfChargingVehicles: "Number of charging vehicles",
    },
    filter: {
      beginTime: "Start Time",
      endTime: "End Time",
      emptyNotice: "Please enter",
      dateAdded: "Date Added",
      administratorAccount: "Administrator Account",
      administratorName: "Administrator Name",
      accountStatus: "Account Status",
      selectEmptyNotice: "Please select",
      select: "Select",
      reset: "Reset",
      inquire: "Search",
      addManager: "Add Administrator",
      enterAdministratorName: "Please enter administrator name",
      maxEnter: "30 characters limit",
      enterAdministratorAccount: "Please enter administrator's mobile number",
      Pleaseenterkeywords: "Please enter keywords",
      PleaseSelectStation: "Please select stations",
      chargingEndTime: "Charging End Time",
      todayButton: "Today",
      yesterdayButton: "Yesterday",
      recent7Days: "Past 7 days",
      recent30Days: "Past 30 days",
      stationName: stationNameConst,
      stationNamePlaceholder: "Enter keyword selection",
      chargingGunNumber: chargingGunNumberConst,
      chargingGunNumberPlaceholder: "Please enter Connector ID",
      userAccount: userAccountConst,
      userAccountPlaceholder: "Please enter phone number",
      PleaseEnterEmailAddress: "Please enter email address",
      orderNumber: orderNumberConst,
      orderNumberPlaceholder: "Please enter transaction number",
      numberplate: "License Plate Number",
      numberplatePlaceholder: "Please enter License Plate number",
      activationMethod: "Activation Method",
      activationMethodPlaceholder: "Please select a activation method",
      chargingStatus: "Charging Status",
      chargingStatusPlaceholder: "Please select a charging status",
      exportReport: "Export",
      PleaseUploadTheFile: "Please Upload The File",
      ModifiedSuccessfully: "Modified Successfully",
      OrdPassword: "Order Password",
      PleaseEnterOriginalPassword: "Please enter original password",
      NewPassword: "New Password",
      PleaseEnterNewPassword: "Please enter new password",
      ConfirmPassword: confirmPasswordConst,
      PleaseEnterTheNewPasswordAgain: "Please enter the new password again",
      tips1: "The maximum interval of a single query is 31 days",
      tips2: "The data statistics time is as of the current time.",
      FundData: "Fund data",
      GetSMSode: "Send OTP",
      SentSuccessfully: "Sent Successfully",
      OperationSuccessfully: "Operation Successfully",
      AccountAllocation: "Amount Allocation",
      CurrentAccount: "Account",
      DistributionAmount: "Amount Allocated(S$)",
      AccountRecovery: "Withdrawal",
      EnterAmount: "Enter Amount(S$)",
      FullRecovery: "Full Withdrawal",
      CustomAmount: "Partial Withdrawal",
      RecoveryAmount: "Please enter the recovery amount",
      BatchAdditionSucceeded: "Batch Addition Succeeded",
      tips3:
        "After disabling, the charging function of the account will be closed. Are you sure to disable it?",
      tips4: "Confirm enabling?",
      tips5: "The driver is currently charging. Do you want to stop charging?",
      tips6: "Order number and charging end time are required",
      Allocatable: "Maximum allocation is S$",
      PleaseEnterANumberGreater: "Please enter a number greater than 0",
      EnterUpTo10Digits: "Enter up to 30 digits",
      AmountRecoveredSuccessfully: "Amount recovered successfully",
      Recyclable: "Maximum Recyclable is S$",
      Second: "s Resend",
      PleaseEnterEmail: "Please enter email",
      PleaseEnterUserName: "Please enter username",
    },
    administratorTable: {
      administratorAccount: "Administrator Account",
      administratorName: "Administrator Name",
      accountStatus: "Status",
      role: "Role",
      updateTime: "Modify Time",
      confirmResetPassword: "Confirm action？",
      resetPassword: "Reset Password",
      yes: "yes",
      no: "no",
      confirm: "Confirm",
      unlock: "Unlock",
      confirmUnlock: "Confirm action？",
      delete: "Delete",
      confirmDelete: "Are you sure?",
      enable: "Activate",
      disable: "Disable",
      superAdmin: "SuperAccount",
      normalAdmin: "NormalAccount",
      normal: "Active",
      add: "Add",
      default: "Default",
      Cancel: "Cancel",
    },
    carInfo: {
      stationSelfCoding: "Self Coding",
      addCar: "Add Car",
      notice:
        "Special note: some vehicles or charging piles do not support obtaining VIN code, and the actual charging order may not obtain vehicle information.",
    },
    account: {
      account: "Account",
      userName: "User Name",
      accountType: "Account Type",
      accountStatus: "Account Status",
      addAccount: "Add Fleet Member",
      balance: "Balance(S$)",
      operator: "Operator",
      operateTime: "Operate Time",
      chargingSetting: "Charging Setting",
      more: "More",
      edit: "Edit",
      UserName: "User Name",
      delete: "Delete",
      recycle: "Withdraw Amount",
      recycleTip: "There is no recoverable balance",
      distribute: "Allocate Amount",
      disable: "Disabled",
      enable: "Active",
      Stopping: "Stopping",
      normal: "Active",
      PleaseCompleteTheInformation: "Please complete the information",
    },
    changeLog: {
      changeDate: "Change Date",
      typeOfChange: "Type of Change",
      subjectOfChange: "Fleet Name / Fleet Member Account",
      summary: "Amount summary in the period(S$)",
      serialNumber: orderNumberConst,
      operator: "Operator",
      operateTime: "Time Modified",
      changeAmount: "Change Amount(S$)",
      currentBalance: "Current Balance(S$)",
      affiliatedCompany: "Affiliated Company",
      Taxi: "Taxi",
      UberCar: "Uber Car",
      Bus: "Bus",
      RentalCar: "Rental Car",
      LogisticsExpress: "Logistics Express",
      Passengerbus: "Passenger Bus",
      Carrental: "Car rental",
      MainEngineFactory: "Main Engine Factory",
      SmallBusiness: "Small Business",
      GovernmentRelated: "Government Related",
      WanBangInternal: "Wan Bang Internal",
    },
    occupation: {
      unit: "S$/kWh",
      peak: "Peak",
      tip: "Tip",
      usually: "Off-peak",
      vec: "Shoulder",
      timeUnit: "min",
      priceUnit: "S$",
      stationName: stationNameConst,
      userAccount: userAccountConst,
      searchOrder: "Transaction Search",
      startTime: "Occupation Start Time",
      chargingDevice: "Charging APP",
      gunNumber: chargingGunNumberConst,
      orderStatus: "Transaction Status",
      complaintStatus: "Complaint Status",
      chargingInfo: "Charging Information",
      occupationInfo: "Occupation Information",
      paymentStatus: "Payment Status",
      status: "Status",
      operation: "Operation",
      enterpriseName: "Enterprise Name",
      transactionID: "Transaction ID",
      billingPrice: "Billing Price",
      maximumAmount: "Maximum Amount",
      paymentTime: "Payment Time",
      chargingAPP: "Charging APP",
      occupationTimeDuration: "Occupation Time Duration",
      payableAmount: "Payable Amount",
      chargingTimeDuration: "Charging Time Duration",
      freeDuration: "Free Duration",
      amountPaid: "The amount paid",
      occupationTime: "Occupation Time",
      billingTimeDuration: "Billing Time Duration",
      viewClaims: "View Claims",
      chargingTransaction: "Charging Transaction",
      billingAmount: "Billing Amount",
      complain: "Complain",
      Discount: "Discount",
      FixedServiceFee: "Fixed Service Fee",
      FixedTotalPrice: "Fixed Total Price",
      ServiceChargeFixed: "Service Charge Fixed",
      ServiceFeeDiscount: "Service Fee Discount",
      ElectricityDiscount: "Electricity Discount",
      TimePeriodDiscount: "Time Period Discount",
      FixedDiscountForElectricityAndServiceCharges:
        "Fixed Discount For Electricity And Service Charges",
    },
    baseInfo: {
      basicInformation: "Basic Information",
      invoiceInformation: "Invoice Information",
      fleetName: "Fleet Name",
      ownerName: "Owner Name",
      ownerMobile: "Owner Mobile",
      identificationNumber: "Identification Number",
      useOfVehicle: "Use of Vehicle",
      permissionOfStation: "Permission of Station",
      detail: "Details",
      invoiceType: "Invoice Type",
      invoiceTitle: "Title",
      accountInformation: "Account Information",
      statisticsAreAsOfTheCurrentUpdateTime:
        "Statistics are as of the current update time.",
      balance: "Total account amount(S$)",
      changeLog: "Change Log",
      account: "Account",
      adminAccount: "Admin Account",
      switch: "Change Account Number",
      adminName: "Admin Name",
      status: "Status",
      fleetMember: "Fleet Member",
      numberOfDrivers: "Number of Drivers",
      numberOfVehicles: "Number of Vehicles",
      ChangeAdministratorAccount: "Change Administrator Account",
      CurrentMobileNumber: "Current Mobile Number",
      CurrentAccountVerificationCode: "Current Account Verification Code",
      NewMobileNumber: "New Mobile Number",
      phoneTips1: "The phone number has been changed to",
      PleaseLoginAgain: "Please Login Again",
      LoginAgain: "Login Again",
      phoneTips2: " Regain",
      phoneTips3:
        "Enter the SMS verification code received by the new mobile phone number",
      phoneTips4: "Please enter a new phone number",
      phoneTips5:
        "Enter the SMS verification code received by the current mobile phone number",
      NewMobileNumberverificationCode: "New Mobile Number verification Code",
    },
    chargingTransaction: {
      stationName: stationNameConst,
      endTimeOfCharging: "End time of charging",
      today: "Today",
      yesterday: "Yesterday",
      nearly7Days: "Past 7 days",
      nearly30Days: "Past 30 days",
      paymentType: "Payment Type",
      paymentStatus: "Payment Status",
      all: "All",
      personal: "Personal",
      Enterprise: "Enterprise",
      personalPayment: "Personal Payment",
      fleetPayment: "Fleet Payment",
      partnerPayment: "Partner Payment",
      creditCardPayment: "Credit Card Payment",
      paid: "Paid",
      unpaid: "Unpaid",
      userAccount: userAccountConst,
      enterpriseName: "Enterprise Name",
      vehicleInformation: "Vehicle Information",
      VINCode: "VIN Code",
      plateNumber: "Licensed Plate Number",
      connectorID: chargingGunNumberConst,
      startReason: "Start Reason",
      chargingStatus: "Charging Status",
      transactionNumber: orderNumberConst,
      transactionStatus: "Transaction Status",
      stopReason: "Stop Reason",
      highRiskModels: "High-risk Models",
      SelectVehicle: "Select Vehicle",
      // stationName: "电站名称",
      // transactionNumber: "订单号",
      // connectorID: "枪编号",
      startChargingTime: "Start Time",
      initialSOC: "Initial SOC",
      transactionChargingVolume: "Energy Consumed (kWh)",
      startMethod: "Start Method",
      endChargingTime: "End Time",
      endSOC: "End SOC",
      originalElectricityPrice: "Electricity Price (S$)",
      userName: "User Name",
      originalServicePrice: "Service Price (S$)",
      totalFeeOfTheTransaction: "Fee (S$)",
      transactionDetails: "Transaction Details",
      chargingProcess: "Charging Process",
      AppletStartup: "Applet Startup",
      PowerCardStart: "Power Card Start",
      OnlineVin: "Online Vin",
      AppStartup: "App Startup",
      charging: "Charging",
      ChargingCompleted: "Charging Completed",
      SharedAccount: "Shared Account",
      IndependentAccount: "Individual Account",
      submit: "Submit",
      UnbindingSucceeded: "Unbinding Succeeded",
      UnbindVehicle: "Unbind Vehicle",
      AreUouSureYouWantToUnbind: "Are Uou Sure You Want To Unbind?",
      ChargingStation: "Charging Stations",
      UnlimitedStation: "All",
      DesignatedStation: "Designated Stations",
      ChargingPeriod: "Charging Period",
    },
    chargingBill: {
      billDate: "Bill Date",
      addBill: "Add Bill",
      billPeriod: "Bill Period",
      name: "Name",
      electricityPrice: "Electricity Price",
      billTime: "Bill Time",
      operator: "Operator",
      serviceFee: "Service Fee",
      chargingVolume: "Charging Volume",
      totalAmount: "Total Amount",
      orderDetail: "Order Detail",
      unit: "kWh",
      // bill,
    },
    VehicleInformation: {
      SelfEncoding: "Self-encoding",
      // DriverAccount: "Driver account",
      TimeModified: "Time modified",
      ModifyInformation: "Modify information",
      delete: "delete",
      NumberPlate: "number plate",
      VinCode: "VIN code",
      SpecialInstructions:
        "Special instructions:Some vehicles or charging piles do not support obtaining VIN code, and vehicle information may not be obtained in actual charging orders.",
      singleNew: "Individual",
      batchNew: "Batch Addition",
      newWay: "Add New Fleet Member(s)",
      AddOrEditVehicles: "Add/edit vehicles",
      PleaseEnterLicensePlateNumber: "Please enter license plate number",
      PleaseEnterVINCode: "Please Enter VIN Code",
      UploadFailList: "Error Report",
      tips1:
        "1、Download the template and fill in the vehicle information in the template",
      DriverInformation: "Driver Information",
      DriverAccount: "Driver Account",
      PleaseEnterDriverAccount: "Please enter email",
      PleaseEnterOneUserName: "Please enter one user name",
      tips2: "Only the entered vehicles can be selected.",
      tips3:
        "The current driver has an order in progress. The saved information will take effect after the order is completed",
      tips4:
        "After switching to a shared account, the funds under the independent account will not be recovered actively. Please pay attention to recovering the funds in time",
      tips5:
        "The current electric card has a driver account and cannot be added again.",
      tips6:
        "The current vehicle already has a driver account and cannot be added again.",
      tips7: "The start time cannot be greater than the end time",
      tips8:
        "The time period cannot be repeated. Please check your time period",
      tips9: "Time intervals must not cross or coincide",
      tips10:
        "If there is an ongoing charging session, changes made will only be updated after the changing session ends.",
      tips11: "Please fill in the new administrator account",
    },
    chargingStation: {
      StationName: stationNameConst,
      AffiliatedCompany: "Affiliated Company",
      AccountManagerName: "Account Manager",
      AccountManagerMobileNumber: "Account Manager Mobile Number",
      StationAddress: "Station Address",
      DCcharger: "DC charger",
      ExclusiveDiscount: "Discount Type",
      DiscountEffectiveTime: "Discount Period",
      serviceHours: "Opening Hours",
      ACcharger: "AC Charger",
      DiscountTypeValue: "Discount Type Value",
      TransactionNotification: "Transaction Notification",
      Unread: "Unread",
      NoMessageContent: "No Message Content",
      hoursAgo: "hours Ago",
      minutesAgo: "minutes Ago",
      secondsAgo: "seconds Ago",
      NoDownloadLink: "No Download Link",
      PleaseCloseExtraWindows: "Please close extra tabs!",
      MarkRead: "Mark Read",
    },
    ElectricityCard: {
      cardNumber: "The card number",
      BindingSubject: "Binding subject",
      BindingState: "Binding state",
      TheElectricCardStatus: "The electric card status",
      AddTheElectricCard: "Add the electric card",
      BatchAdd: "Batch add",
      disable: "disable",
      delete: "delete",
      enable: "enable",
      TimeModified: "Time modified",
      UploadFailureList: "Upload failure list",
      ClickDownload: "Download Report",
      downloadStep1:
        "Please fill in the required information in the template provided.",
      downloadStep2: "Upload completed template by clicking the button below.",
      UploadFile: "Upload File",
      DownloadTemplate: "Download Template",
      ElectricCardInfo: "Electric Card Info",
      SelectElectricCard: "Select Electric Card",
      UnbindElectricCard: "Unbind Electric Card",
      ElectricCardNumber: "Electric Card Number",
    },
    orderDetail: {
      orderDetail: "Transaction Details",
      carselfcoding: "Car self-coding",
      LengthTime: "Charging Duration",
      InitialSOC: "Initial SOC",
      EndSOC: "End SOC",
      Timeofpayment: "Time of payment",
      MethodOfPayment: "Method of payment",
      Amountplatform: "Fee (S$)",
      Discountamount: "Discount Amount (S$)",
      Discounttype: "Discount type",
      actuallyPaid: "Total Fee (S$)",
      ExporSrder: "Export",
      OrderTime: "Order Time",
      CollatingTableDateExport:
        "Collating table data to export, please wait...",
      YouCanLeaveThisPage:
        'You can leave this page. The export will be carried out in the background. Please go to "notice" to download after completion.',
      Ok: "Ok",
    },
    viewcomplaint: {
      viewcomplaint: "View complaint",
      Complaintdescription: "Complaint description",
      Complainpicture: "Complain picture",
    },
    billDetail: {
      exportStatement: "File export",
      orderNO: "Order No.",
      powerStationName: "Station name",
      userAccount: userAccountConst,
      chargingAmount: "Charging Amount ",
      OriginalElectricityPrice: "Original Electricity Price",
      Connectornumber: "Connector number ",
      licenseNumber: "License No.",
      actuallyPaid: "Total Fee",
      ChargingTime: "Charging time",
    },
    VINPermissions: {
      whetherEnable: "Whether to enable VIN authentication",
      No: "No",
      Yes: "Yes",
      Instructions: "Instructions",
      tips:
        "When the VIN authentication switch is turned on, the employee uses the enterprise account to charge, and the current vehicle will be checked whether it is binded or not. If the vehicle cannot be started without binding, please use the personal account to start it.",
      msg:
        "When the VIN uthentication switch is turned off, the employee uses the enterprise account to charge and does not conduct vehicle verification. If there is a balance, the vehicle can be started normally.",
      Save: "Save",
    },
    Employee: {
      Username: "User Name",
      MobilePhoneNumber: "Mobile phone number",
      ReportType: "Report type",
      Date: "Date",
      NumberOfCharging: "Number of charging",
      ChargingDuration: "Charging duration",
      Search: "Search",
      Export: "Export",
      Totalchargingamount: "Total charging amount (Kwh)",
      Chargingcost: "Charging Cost (YUAN)",
      Electricityfee: "Electricity fee (YUAN)",
      Servicefee: "Service fee (YUAN)",
      ElectricQuantityatshoulderhour:
        "Electric Quantity at shoulder hour (Kwh)",
      ElectricityFeeatshoulderhour: "Electricity Fee at shoulder hour (YUAN)",
      ServiceFeeatshoulderhour: "Service Fee at shoulder hour (YUAN)",
      ElectricQuantityatsharphour: "Electric Quantity at sharp hour (Kwh)",
      ElectricityFeeatsharphour: "Electricity Fee at sharp hour (YUAN)",
      ServiceFeeatsharphour: "Service Fee at sharp hour (YUAN)",
      ElectricQuantityatpeakhour: "Electric Quantity at peak hour (Kwh)",
      ElectricityFeeatpeakhour: "Electricity Fee at peak hour (YUAN)",
      ServiceFeeatpeakhour: "Service Fee at peak hour (YUAN)",
      ElectricQuantityatoffpeakhour: "Electric Quantity at off-peak hour (Kwh)",
      ElectricityFeehour: "Electricity Fee at off-peak hour (YUAN)",
      ServiceFeehour: "Service Fee at off-peak hour (YUAN)",
      LicenseplateNo: "License plate No.",
      Vehiclecode: "Vehicle code",
      date: "Date",
      month: "Month",
      year: "Year",
      other: "Period",
      other1: "Upper",
      other2: "Middle",
      other3: "Lower",
      self: "Time",
      monthSelect: "Month",
      yearSelect: "Year",
      otherSelect: "Period",
      selfSelect: "Time",
    },
  },
};
