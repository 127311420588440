import request from "@/utils/request";

/**
 * 获取四家账户列表
 * @param params
 * @returns {Promise<any>}
 */
export async function queryAccountList(params) {
  return request.post("/mtsV2/car/account/info/list/qry", { params });
}

/**
 * 添加司机账户
 * @param params
 * @returns {Promise<any>}
 */
export async function addDriverAccount(params) {
  return request.post("/mtsV2/account/info/add", { params });
}

/**
 * 司机账号修改
 * @param params
 * @returns {Promise<any>}
 */
export async function updateAccountInfo(params) {
  return request.post("/mtsV2/account/info/edit", { params });
}

/**
 * 回收金额
 * @param params
 * @returns {Promise<any>}
 */
export async function recycleMoney(params) {
  return request.post("/mtsV2/account/amount/recycle", { params });
}

/**
 * 分配金额
 * @param params
 * @returns {Promise<any>}
 */
export async function distributionMoney(params) {
  return request.post("/mtsV2/account/amount/distribution", { params });
}

/**
 * 更改账号状态
 * @param params
 * @returns {Promise<any>}
 */
export async function updateAccountStatus(params) {
  return request.post("/mtsV2/fleet/account/status/update", { params });
}

/**
 * 验证当前账户是否在充电
 * @param params
 * @returns {Promise<any>}
 */
export async function validatorChargingType(params) {
  return request.get("/mtsV2/isCharging", { params });
}

/**
 * 批量导入司机
 * @param params
 * @returns {Promise<any>}
 */
export async function uploadDriver(params) {
  return request.post("/mtsV2/drivers/import", { params });
}

/**
 * 停止充电
 * @param params
 * @returns {Promise<any>}
 */
export async function stopCharge(params) {
  return request.post("/mtsV2/account/stopOrder", { params });
}

/**
 * 获取当前车队账户明细
 * @param params
 * @returns {Promise<any>}
 */
export async function queryAccountDetailInfo(params) {
  return request.get("/mtsV2/fleet/account/amount/qry", { params });
}

/**
 * 获取充电限制
 * @param params
 * @returns {Promise<any>}
 */
export async function queryChargeLimit(params) {
  return request.get("/mtsV2/account/getChargingSetting", { params });
}

/**
 * 司机账户充电限制保存
 * @param params
 * @returns {Promise<any>}
 */
export async function saveChargeLimit(params) {
  return request.get("/mtsV2/account/chargingSetting", { params });
}

/**
 * 解绑车辆
 * @param params
 * @returns {Promise<any>}
 */
export async function delCar(params) {
  return request.post("/mtsV2/account/info/delCar", { params });
}
