import request from "@/utils/request";

/**
 * 登陆接口，获取token
 * @param params
 * @returns {Promise<any>}
 */
export async function getLoginKey(params) {
  return request.get("/mtsV2/ignore/login", {
    params,
  });
}

/**
 * 获取登陆车队信息
 * @returns {Promise<any>}
 */
export async function getLoginInfo() {
  return request.post("/mtsV2/fleet/operation");
}

/**
 * 获取短信验证码，用于找寻密码
 * @param params
 * @returns {Promise<any>}
 */
export async function getSmsCode1(params) {
  return request.get("/mtsV2/ignore/password/sms/code", { params });
}

/**
 * 获取短信验证码，用于解锁账号
 * @param params
 * @returns {Promise<any>}
 */
export async function getSmsCode2(params) {
  return request.get("/mtsV2/ignore/smsCode", { params });
}

/**
 * 修改密码
 * @param params
 * @returns {Promise<any>}
 */
export async function updatePwd(params) {
  return request.get("/mtsV2/ignore/edit/user/password", { params });
}

/**
 *
 * @param params
 * @returns {Promise<any>}
 */
export async function lockSmsCodeValidator(params) {
  return request.get("/mtsV2/ignore/checkSmsCode", { params });
}

/**
 * 第一次登陆修改密码
 * @param params
 * @returns {Promise<any>}
 */
export async function changePwdWhenFirstLogin(params) {
  return request.get("/mtsV2/ignore/changePassWord", { params });
}

/**
 * 退出登录
 * */

export async function logout(params) {
  return request.post("/mtsV2/logout", { params });
}
