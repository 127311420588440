import { addCard, batchAddCard, exportCard, enabledCardState, disabledCardState, deleteCard } from "@/pages/fleet/electricCard/service";
import contextApi from "@/utils/contextApi";

const Model = {
  namespace: "electricCardModel",
  state: {
    editVisible: false,
    record: null,
    // 模板信息
    fileList: [],
    errUrl: null,
  },
  effects: {
    *patch({ payload }, { put }) {
      yield put({
        type: "setState",
        payload,
      });
    },
    /**
     * 新增电卡
     * @param payload
     * @param call
     * @param put
     * @returns {Generator<*, void, *>}
     */
    *submitCardInfo({ payload }, { call, put }) {
      const res = yield call(addCard, payload);

      if (res && res.code === "200") {
        return true;
      }
      return false;
    },
    *batchSubmitCardInfo({ payload }, { call, put }) {
      console.log('666666666',payload)
      const res = yield call(batchAddCard, payload);

      if (res && res.code === "200") {
        return true;
      }
      return false;
    },
    //启用
    *enabled({ payload }, { call }) {
      const res = yield call(enabledCardState, payload);


      if (res && res.code === "200") {
        contextApi("message").success("操作成功");
      }
    },
    //禁用
    *disabled({ payload }, { call }) {
      const res = yield call(disabledCardState, payload);

      if (res && res.code === "200") {
        contextApi("message").success("操作成功");
      }
    },
    //删除
    *delete({ payload }, { call }) {
      const res = yield call(deleteCard, payload);

      if (res && res.code === "200") {
        contextApi("message").success("操作成功");
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
export default Model;
