import { getIntl } from "@/.umi/plugin-locale/localeExports";
import {
  addChildAdmin,
  deleteChildAdmin,
  disabledAdminAccount,
  enabledAdminAccount,
  resetPassword,
  unlockAccount,
} from "@/pages/basicSetting/administrator/service";
import contextApi from "@/utils/contextApi";
const Model = {
  namespace: "adminModel",
  state: {},
  effects: {
    *addAdmin({ payload }, { call }) {
      const res = yield call(addChildAdmin, payload);
      if (res && res.code === "200") {
        return true;
      }
      return false;
    },

    *enabled({ payload }, { call }) {
      const intl = getIntl();
      const res = yield call(enabledAdminAccount, payload);
      if (res && res.code === "200") {
        contextApi("message").success(
          intl.messages.page.filter.OperationSuccessfully
        );
      }
    },

    *disabled({ payload }, { call }) {
      const res = yield call(disabledAdminAccount, payload);
      const intl = getIntl();

      if (res && res.code === "200") {
        contextApi("message").success(
          intl.messages.page.filter.OperationSuccessfully
        );
      }
    },

    *reset({ payload }, { call }) {
      const res = yield call(resetPassword, payload);
      const intl = getIntl();

      if (res && res.code === "200") {
        contextApi("message").success(
          intl.messages.page.filter.OperationSuccessfully
        );
      }
    },

    *unlock({ payload }, { call }) {
      const res = yield call(unlockAccount, payload);
      const intl = getIntl();
      if (res && res.code === "200") {
        contextApi("message").success(
          intl.messages.page.filter.OperationSuccessfully
        );
      }
    },

    *delete({ payload }, { call }) {
      const res = yield call(deleteChildAdmin, payload);
      const intl = getIntl();

      if (res && res.code === "200") {
        contextApi("message").success(
          intl.messages.page.filter.OperationSuccessfully
        );
      }
    },
  },
  reducers: {},
};
export default Model;
