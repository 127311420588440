import { evcsSettlementList, evcsDailySummary } from "@/pages/financial/chargingBill/service";

const Model = {
  namespace: "chargingBillModel",
  state: {
    dataSource: {},
  },
  effects: {
    /**
     * 获取账单信息
     * @param payload
     * @param call
     * @param put
     * @returns {Generator<*, void, *>}
     */
    *getRecord({ payload }, { call, put }) {
      const res = yield call(evcsSettlementList, payload);

      if (res && res.code === "200") {
        yield put({
          type: "setState",
          payload: {},
        });
      }
    },
    /**
     * 获取汇总电量数据
     * @param payload
     * @param call
     * @param put
     * @returns {Generator<*, void, *>}
     */
    *getSummary({ payload }, { call, put }) {
      const res = yield call(evcsDailySummary, payload);

      if (res && res.code === "200") {
        yield put({
          type: "setSummary",
          payload: res.data,
        });
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    setSummary(state, { payload }) {
      return { ...state, dataSource: payload };
    },
  },
};
export default Model;
