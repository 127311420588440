import {
  addDriverAccount,
  distributionMoney,
  queryAccountDetailInfo,
  recycleMoney,
  saveChargeLimit,
  updateAccountInfo,
  updateAccountStatus,
  uploadDriver,
} from "@/pages/financial/driverAccount/service";
import contextApi from "@/utils/contextApi";
import sessionManager from "@/utils/sessionStorage";
import { history } from "umi";
const initHistoryAccount = "/financial/account";
const lang = sessionManager.getLasting("local") || "zh-CN";
const Model = {
  namespace: "driverAccountModel",
  state: {
    fileList: [],
    errUrl: null,
    carVisible: false,
    accountInfo: {},
    stopLoading: false,
    cardVisible: false,
  },
  effects: {
    *add({ payload }, { call }) {
      const res = yield call(addDriverAccount, payload);

      if (res && res.code === "200") {
        lang === "en-US"
          ? contextApi("message").success("Operation successfully")
          : contextApi("message").success("新增成功");
        // if (lang =='English') contextApi('message').success('新增成功');
        history.push(initHistoryAccount);
      }
    },

    *upload({ payload }, { call, put }) {
      const res = yield call(uploadDriver, payload);

      if (res && res.code === "200") {
        const data = res.data;
        if (data.failCount === null) {
          contextApi("message").error(data.failFileInfo);
        }
        if (data.failCount === 0&&data.successCount !== 0) {
          lang === "en-US"
            ? contextApi("message").success("Batch addition succeeded")
            : contextApi("message").success("批量新增成功");
          history.push(initHistoryAccount);
        } else {
          lang === "en-US"
            ? contextApi("message").warning(`Errors detected`)
            : contextApi("message").warning(
                `成功${data.successCount}条，失败${data.failCount}条`
              );

          yield put({
            type: "setState",
            payload: {
              errUrl: data.failFileInfo,
            },
          });
        }
      }
    },

    *edit({ payload }, { call }) {
      const res = yield call(updateAccountInfo, payload);

      if (res && res.code === "200") {
        lang === "en-US"
          ? contextApi("message").success("Modified successfully")
          : contextApi("message").success("修改成功");
        history.push(initHistoryAccount);
      }
    },

    *updateStatus({ payload, refresh }, { call }) {
      const res = yield call(updateAccountStatus, payload);
      if (res && res.code === "200") {
        refresh();
      }
    },

    *accountDetail({ payload }, { call, put }) {
      const res = yield call(queryAccountDetailInfo, payload);
      if (res && res.code === "200") {
        yield put({
          type: "setState",
          payload: {
            accountInfo: res.data,
          },
        });
      }
    },

    *distribution({ payload, refresh }, { call }) {
      const res = yield call(distributionMoney, payload);

      if (res && res.code === "200") {
        lang === "en-US"
          ? contextApi("message").success("Operation success")
          : contextApi("message").success("分配金额成功");

        refresh();
        return true;
      } else {
      }
    },

    *recycling({ payload, refresh }, { call }) {
      const res = yield call(recycleMoney, payload);

      if (res && res.code === "200") {
        lang === "en-US"
          ? contextApi("message").success("Operation success")
          : contextApi("message").success("回收金额成功");
        refresh();
      }
    },

    *limit({ payload, refresh }, { call }) {
      const res = yield call(saveChargeLimit, payload);
      console.log(res);
      if (res && res.code === "200") {
        lang === "en-US"
          ? contextApi("message").success("  Charge limit saved successfully")
          : contextApi("message").success("充电限制保存成功");

        refresh();
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
export default Model;
